import {
  Box,
  Typography,
  Tooltip,
  Zoom,
  Button,
  Collapse,
  Grid,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./postsAndReplies.scss";
import {
  CheckValueLocale,
  isArabic,
  isEmptyObj,
  extractDisplayNameFromDataSource,
  sanitizeEngagementChatMessage,
  formatTextToHtml,
  postTimeWithFormatAgo,
  isNullish,
  getEngagementId,
} from "utils/helpers";
import React, { useEffect, useRef, useState } from "react";
import ReplyModal from "../../replyModal/replyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faExternalLink, faBan } from "@fortawesome/pro-regular-svg-icons";
import love from "images/engagements-images/love.svg";
import reacts from "images/engagements-images/reacts.svg";
import replay from "images/engagements-images/replay.svg";
import ezgif from "images/engagements-images/ezgif.gif";

import { useIntl } from "react-intl";
import moment from "moment";
import EngagementsController from "services/controllers/engagementsController";
import profileImg from "images/engagements-images/profileImg.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dummyImage from "images/shared-images/image-placeholder.svg";
import dummyVideo from "images/shared-images/video-placeholder.svg";
import ProfileUser from "../profileUser/profileUser";
import TranslationRating from "../translationRating/translationRating";
import EditAndDeleteButtons from "./editAndDeleteButtons/editAndDeleteButtons";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import EditReply from "./editReply/editReply";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import { faMessageLines, faPlay } from "@fortawesome/pro-solid-svg-icons";
import { MediaPreviewer } from "../../mediaPreviewer/mediaPreviewer";
import LucButton from "shared/lucButton/lucButton";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useEditInteraction } from "../hooks/useEditInteraction";
import { PostTranslation } from "./components/postTranslation";
import { ThemesAndSentiments } from "./components/themesAndSentiments";
import { extractThemesAndSentiments, postTypes } from "./postAndRepliesUtils";
import { classNames } from "utils/helpers";
import { MissingReply } from "./components/missingReply";
import { getWorkingSecondsInTimeRange } from "utils/helpers/sla/slaCalculator";
import {
  engagementTabsActions,
  useEngagementTabs,
} from "pages/engagements/providers/engagementsTabsProvider";

const socialIcons = (type) => {
  const types = {
    facebook: <FontAwesomeIcon icon={faFacebookSquare} />,
    twitter: <FontAwesomeIcon icon={faTwitterSquare} />,
    instagram: <FontAwesomeIcon icon={faInstagram} />,
  };
  return types[type];
};

const PostsAndReplies = ({
  setSelectedCardIteractionID,
  selectedCard,
  setSelectedCard,
  itemSelected,
  headerData,
  setOpenDetailsSideBar,
  text,
  setText,
  setOpenDetailsSideBarReplay,
  setOpenSlaSideBar,
  openSidebar,
  setOpenSidebar,
  checkAiSuggestions,
  setCheckAiSuggestions,
  selectedValue,
  setSelectedValue,
  handelOpenSidebar,
  translationActive,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  translationTarget,
  setTranslationTarget,
  setStats,
  stats,
  languagesArraySource,
  languagesArrayTarget,
  openDetailsSideBarReplay,
  savedSuggTab,
  aiRepsTab,
  setIsCashedReplays,
  isSidebarsOpened,
  showAllIcon,
  aiAgentList,
  setRefresh,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentId,
  replyBoxInputTextRef,
  slaKey,
  callSingleinteraction,
  getHistoricalRequestInteraction,
  socket,
  missingReplyLoadingType,
}) => {
  let statusItemCard = selectedCard
    ? selectedCard?.system_replies?.status
    : selectedCard?.status;
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses?.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses?.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px 8px",
      fontWeight: "500",
    },
  }));
  const intl = useIntl();
  const agentResponseTargetRef = useRef(null); // Ref to the agent response target element
  const isAgentScrolledToTarget = useRef(false); // Ref to check if the agent response target is scrolled to
  const [showSuccess, setShowSuccess] = useState(false);
  const [postData, setPostData] = useState({});
  // threadReplies will hold the main reply and all other reply in this thread if there is any
  const [threadReplies, setThreadReplies] = useState([]);
  // Selected Interactions
  const [selectedInteractions, setSelectedInteractions] = useState({
    sentiment: {},
    sub_themes: {},
    themes: {},
  }); // holds the selected interactions for every postData
  const [interactionLoading, setInteractionLoading] = useState({
    sentiment: {},
    sub_themes: {},
    themes: {},
  }); // state to control the loading when change the sentiment or the category
  const [expandedThemes, setExpandedThemes] = useState({}); // State to control the collapse/expand of themes by post ID.
  const [loadMorePageNumber, setLoadMorePageNumber] = useState(1);
  const [isReplied, setIsReplied] = useState(false);
  // Translation State
  // every post or comment has an index, we use this index to control the translation status for every post or comment
  const [openTranslationCollapse, setOpenTranslationCollapse] = useState({}); // control translation collapse for every main post translation.
  const [openReplyCollapse, setOpenReplyCollapse] = useState({}); // control translation collapse for the agent reply.
  const [translationStatus, setTranslationStatus] = useState({}); // if it's open or not
  const [translationButtonLoading, setTranslationButtonLoading] = useState({}); // if the translation being loaded from the backend
  const [translationButtonResult, setTranslationButtonResult] = useState({}); // the result of the translation after being loaded from the backend
  const [translateStatus, setTranslateStatus] = useState(false); // passed to the ReplyModal to control the translation button

  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);

  const [openDeleteModalStats, setOpenDeleteModalStats] = useState({
    openDeleteModal: false,
    snackBarAction: "",
    snackBarSeverity: "",
    id: "",
    checkMainPost: "",
  });
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [isReplyDeleted, setIsReplyDeleted] = useState(false);
  const [showEditDeleteBtns, setShowEditDeleteBtns] = useState(false);
  const [isDeletedResponse, setIsDeletedResponse] = useState(false);
  // media previewer state
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [media, setMedia] = useState([]);
  const [defaultMediaIndex, setDefaultMediaIndex] = useState(0);

  const [maxChar, setMaxChar] = useState(280);
  const [editText, setEditText] = useState(
    selectedCard?.system_replies?.replier_info?.text,
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeReplyEditEngagement, setActiveReplyEditEngagement] =
    useState(false);
  const [editReplyDisable, setEditReplyDisable] = useState(false);
  const [editTooltipActive, setEditTooltipActive] = useState(false);
  const [editTooltipDate, setEditTooltipDate] = useState("");
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const userAssignedToChat = reduxSlaData?.[slaKey]?.userId;
  const signedInUser = localStorage?.getItem("user_id");
  const authorizedToEditAndDelete = +signedInUser == +userAssignedToChat;

  const isThreadPost =
    !!selectedCard?.thread?.length ||
    !!selectedCard?.root_interaction?.tweet_id; // indicates if the selected card is a thread post in twitter
  const isRootMissing =
    selectedCard?.root_request_historical ||
    !selectedCard?.root_interaction?.tweet_id; // indicates if the root post is missing

  let companyTimeZone = window?.localStorage?.companyInfo;
  const editDateTooltip = (editTooltipDate) => {
    const editDateTooltip = moment
      .unix(editTooltipDate)
      .utc()
      .format("LT")
      .split(" ");
    return (
      <Box className="edit-date-tooltip">
        <Box component="span">
          {moment.unix(editTooltipDate).utc().format("YYYY/MM/DD")}
        </Box>
        <Box component="span">{CheckValueLocale("at", "", {}, intl)}</Box>
        <Box component="span">
          {editDateTooltip?.length
            ? ` ${editDateTooltip[0]} ${CheckValueLocale(
                editDateTooltip[1] == "AM"
                  ? "hashtag_period_am"
                  : "hashtag_period_pm",
                "",
                {},
                intl,
              )}`
            : null}
        </Box>
      </Box>
    );
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: false,
    });
  };

  const handleOpenDeleteModal = (event, id, mainPost) => {
    event.stopPropagation();
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: true,
      id,
      checkMainPost: mainPost,
    });
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };
  const { handleEngagementTabs } = useEngagementTabs();

  let checkDataSource =
    postData?.data_source === "INSTAGRAM"
      ? (postData?.replies?.[0]?.account_identifier ??
        postData?.system_replies?.replier_info?.instagram_id)
      : postData?.data_source === "TWITTER" &&
          openDeleteModalStats?.checkMainPost
        ? (postData?.root_interaction?.created_by ?? null) // Twitter Thread Post logic
        : postData?.data_source === "TWITTER"
          ? (postData?.replies?.[0]?.name ??
            postData?.system_replies?.replier_info?.screen_name) // Regular Twitter Reply logic
          : postData?.data_source === "FACEBOOK"
            ? (postData?.replies?.[0]?.account_identifier ??
              postData?.system_replies?.replier_info?.page_id)
            : postData?.replies && Object.keys(postData?.replies)?.length > 0
              ? postData?.replies[Object.keys(postData?.replies)[0]]
                  ?.account_identifier
              : undefined; // default fallback, just `undefined` when nothing is found

  //Handle Delte Reply
  const handleDeleteReply = (replyId) => {
    const dataSourceId = +postData?.channel_id;
    const monitorId = +postData?.tracker_id;
    const postType = postData?.system_replies?.replier_info?.item_type;
    const parentId = openDeleteModalStats?.checkMainPost
      ? postData?.root_id
      : postData?.tweet_id || postData?.id;
    setDisabledDeleteBtn(true);
    EngagementsController.deleteEngagementPost(
      replyId,
      dataSourceId,
      monitorId,
      postType,
      window.localStorage.getItem("engagementsProductId"),
      checkDataSource,
      parentId,
    ).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setPostData({
          ...postData,
          replies: [],
          system_replies: {
            ...(postData?.system_replies || {}),
            replier_info: {},
          },
        });
        setSelectedCard((prev) => ({
          ...prev,
          replies: [],
          system_replies: {
            ...(prev?.system_replies || {}),
            replier_info: {},
          },
        }));
        setIsReplied(false);
        setDisabledDeleteBtn(false);
        handleCloseDeleteModal();
        setIsReplyDeleted(true);
        setShowEditDeleteBtns(false);

        setIsDeletedResponse(res?.data?.data?.is_deleted);
        setOpenSnackBarDelete(true);
        setSnackBarData({
          severity: "success",
          message: "delete_reply_success_msg",
        });
        if (
          res?.data?.data?.is_deleted &&
          (replyId == postData?.tweet_id ||
            replyId == postData?.interaction_source_parent_id ||
            openDeleteModalStats?.checkMainPost) // Check if root post is deleted [ if true we close tab and reload engagements ]
        ) {
          const engagementId = getEngagementId(selectedCard);
          handleEngagementTabs?.(engagementTabsActions.remove_tab, {
            id: engagementId,
          });
          setRefresh();
        }
      } else {
        handleCloseDeleteModal();
        setDisabledDeleteBtn(false);
        setSnackBarData({
          message: "something_went_wrong",
          severity: "error",
        });
        setOpenSnackBarDelete(true);
      }
    });
  };

  //Handle Edit reply
  const handleEditReply = (replyId) => {
    const formData = new FormData();
    let objData = {
      id: replyId,
      data_source_id: postData?.channel_id,
      monitor_model_id: postData?.tracker_id,
      text: editText,
      product_id: window.localStorage.getItem("engagementsProductId"),
      parent_id: postData?.tweet_id || postData?.id,
      account_identifier: checkDataSource,
    };
    formData.append("published", JSON.stringify(objData));
    setBtnLoading(true);
    setEditReplyDisable(true);
    EngagementsController.editReply(formData).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setOpenSnackBarDelete(true);
        setActiveReplyEditEngagement(false);
        setEditTooltipActive(true);
        setEditReplyDisable(false);
        setBtnLoading(false);
        setEditTooltipDate(res?.data?.data?.date);
        setSnackBarData({
          severity: "success",
          title: "reply_edited_successfully",
        });
      } else {
        setOpenSnackBarDelete(true);
        setActiveReplyEditEngagement(false);
        setEditReplyDisable(false);
        setBtnLoading(false);
        setSnackBarData({
          title: "something_went_wrong",
          severity: "error",
        });
      }
    });
  };

  //Delete Reply Message
  const deleteReplyMsg = (postData) => {
    return (
      <Box className="delete-msg-wrapper">
        {postData?.system_replies?.replier_info !== null ? (
          <Box className="delete-msg-reply-by">
            {`${CheckValueLocale("replied_by", "", {}, intl)}: `}
            <Box component="span">
              {postData?.system_replies?.replier_info?.email}
            </Box>
          </Box>
        ) : null}
        <Box className="delete-msg-comments">
          {" "}
          <FontAwesomeIcon icon={faBan} />
          {CheckValueLocale("comment_was_deleted", "", {}, intl)}{" "}
        </Box>
      </Box>
    );
  };

  const handleOnLoadMore = () => {
    const lastReplyTweetId = threadReplies?.[0]?.tweet_id;
    const lastReplyParentId = threadReplies?.[0]?.interaction_source_parent_id;
    const nextPage = loadMorePageNumber + 1;
    setLoadMorePageNumber(nextPage);
    callSingleinteraction(null, {
      page_number: nextPage,
      tweet_id: lastReplyTweetId,
      lastReplyParentId,
    });
  };

  const handleHistoricalRequest = (isRoot) => {
    const lastReplyParentId = threadReplies?.[0]?.interaction_source_parent_id;
    getHistoricalRequestInteraction({
      lastReplyParentId,
      isRoot,
    });
  };
  // handle sla frt counter when create replay
  useEffect(() => {
    let slaObj = { ...reduxSlaData };
    let slaSelectedObj = {
      ...slaObj[
        `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
      ],
    };
    let repliesDataFiltered = postData?.replies?.length
      ? [...postData?.replies]?.filter(
          (item) => item?.email != "automated_public_reply",
        )
      : [];

    const getAgentWorkingHoursInfoForInteraction = (
      interactionCreatedAtUnix,
      endTimeUnix,
    ) => {
      const engagementId = `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`;
      const teamId = reduxSlaData[engagementId]?.teamId;
      const teamWorkingHours = reduxSlaData?.teams?.find(
        (team) => +team?.id === +teamId,
      )?.team_working_time?.[0]?.working_time;
      return getWorkingSecondsInTimeRange(
        interactionCreatedAtUnix,
        endTimeUnix,
        teamWorkingHours,
      );
    };

    // first we check if frt counter is live , and if it is and user create a replay we update frtSec to stop it
    if (
      slaSelectedObj?.slaId &&
      isNullish(slaSelectedObj?.frtSec) &&
      repliesDataFiltered?.[0]?.created_at
    ) {
      let frtDeadline =
        repliesDataFiltered?.[0]?.created_at - itemSelected?.created_at;
      const {
        workingSeconds,
        isAgentWorkingNow,
        secondsUntilNextShift,
        hasWorkingHours,
        teamWorkingHours,
      } = getAgentWorkingHoursInfoForInteraction(
        itemSelected?.created_at,
        repliesDataFiltered?.[0]?.created_at,
      );
      if (hasWorkingHours) {
        frtDeadline = workingSeconds;
      }

      slaSelectedObj.frtSec = frtDeadline;
      slaSelectedObj.frtDate = repliesDataFiltered?.[0]?.created_at;
      slaObj[
        `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
      ] = {
        ...slaSelectedObj,
      };
      dispatch(slaData(slaObj));
    }
  }, [postData]);

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });

  const handleAutoScroll = () => {
    if (!isThreadPost || isAgentScrolledToTarget.current) return;

    setTimeout(() => {
      const shouldScroll =
        agentResponseTargetRef.current && !isAgentScrolledToTarget.current;
      if (shouldScroll) {
        agentResponseTargetRef.current?.scrollIntoView?.({
          behavior: "smooth",
          block: "center",
        });
        isAgentScrolledToTarget.current = true;
      }
    }, 300); // Defer the scroll to the agent response target to make sure the element is rendered
  };

  useEffect(() => {
    setIsCashedReplays(true);
    setPostData({ ...selectedCard });
    // we want to make an array that will holds all the replies in this thread
    // if it's a thread post this means there might be more than one reply in this thread
    // otherewise there will be one reply in case of instagram or facebook
    const replies = isThreadPost
      ? [...selectedCard?.thread].reverse()
      : [selectedCard];
    // set the threadReplies state with the replies array
    setThreadReplies([...replies]);

    // then we want to get the sentiment, themes, and subthemes for every reply in this thread
    // so we will append the theard root to the replies array if it's a thread post
    if (isThreadPost && !isRootMissing) {
      replies.unshift(selectedCard?.root_interaction);
    }
    // then we will extract the sentiment, themes, and subthemes for every reply in this thread
    const { sentiments, subthemes, themes } = extractThemesAndSentiments(
      replies,
      { sentimentsOptions, selectedInteractions },
    );

    setSelectedInteractions({
      sentiment: sentiments,
      sub_themes: subthemes,
      themes,
    });

    // if it's a thread post we want to highlight the reply that the agent should reply to
    handleAutoScroll();
  }, [selectedCard, sentimentsOptions]);

  const { mutate: editInteractions, isPending: editInteractionLoading } =
    useEditInteraction();

  // Updates a specific interaction type (sentiment, themes, or sub_themes)
  // for a particular post ID.
  const handleInteractionChange = (interactionType, postId, newValue) => {
    setSelectedInteractions((prev) => ({
      ...prev,
      [interactionType]: {
        ...(prev[interactionType] || {}),
        [postId]: newValue,
      },
    }));
  };
  // Controls loading states for a specific interaction type (sentiment, themes, or sub_themes)
  // and a specific post ID.
  const handleInteractionLoading = (interactionType, postId, isLoading) => {
    setInteractionLoading((prev) => ({
      ...prev,
      [interactionType]: {
        ...(prev[interactionType] || {}),
        [postId]: isLoading,
      },
    }));
  };

  // Toggles the expanded/collapsed state of themes for a specific post ID.
  const toggleThemesExpansion = (postId) => {
    setExpandedThemes((prev) => ({
      ...prev,
      [postId]: prev[postId] ? false : true,
    }));
  };

  const handleChangeInteraction = (
    interaction_type,
    newValue,
    replyId,
    oldValue,
  ) => {
    handleInteractionLoading(interaction_type, replyId, true);

    editInteractions(
      {
        queryData: {
          product_id: +window.localStorage.getItem("engagementsProductId"),
          monitor_id: +selectedCard?.tracker_id,
          id: replyId,
          data_source_name: selectedCard?.data_source,
          new_label: newValue,
          old_label: oldValue,
          interaction_type,
        },
      },
      {
        onSuccess: () => {
          handleInteractionChange(interaction_type, replyId, newValue);
        },
        onSettled: () => {
          handleInteractionLoading(interaction_type, replyId, false);
        },
      },
    );
  };

  const handleFullDateTimeFormat = (date) => {
    let dateHandler = moment
      .unix(date)
      .utc()
      .format("DD/MM/YYYY, hh:mm A")
      .split(" ");
    let dateFormat = `${dateHandler?.[0]} ${dateHandler?.[1]} ${CheckValueLocale(dateHandler?.[2] == "PM" ? "post_date_period_pm" : "post_date_period_am", "", {}, intl)}`;
    return dateFormat;
  };

  const posts = (obj, haveRep, mainPost, postType) => {
    const postReplyId = obj?.tweet_id;
    const isRootPost = postType === "root";
    const isAgentResponseTarget =
      isThreadPost && !isRootPost && selectedCard?.tweet_id === postReplyId; // indicates if this is the reply that the agent should reply to.

    let text =
      postData?.data_source == "TWITTER" && obj?.tweet_full_text
        ? obj?.tweet_full_text
        : obj?.text;
    // inc case of twitter and there are med, twitter is adding a link in the end of the text message,
    // we want to remove this link from the text message if it exists
    // this link represent the tweet link, so we will the get the tweet url from the response and replace it with empty string in the text message
    if (obj?.data_source == "TWITTER" && obj?.media?.length > 0) {
      obj?.media?.forEach?.((mediaItem) => {
        const tweetURL = mediaItem?.url;
        text = text?.replace(` ${tweetURL}`, "");
      });
    }
    let mediaSize =
      obj.media?.length == 1
        ? text
          ? 114
          : 150
        : obj.media?.length == 2 || obj.media?.length == 3
          ? text
            ? 80
            : 150
          : obj.media?.length == 4
            ? text
              ? 60
              : 130
            : 60;

    let sumOfValues = (obj) => {
      var sum = 0;
      for (var el in obj) {
        if (obj?.hasOwnProperty(el)) {
          sum += obj[el];
        }
      }
      return sum;
    };
    let reactsCount = obj?.heart_count
      ? `${obj?.heart_count}`
      : obj?.reactions && !isEmptyObj(obj?.reactions)
        ? sumOfValues(obj?.reactions)
        : "0";

    const displayName = extractDisplayNameFromDataSource(
      selectedCard,
      obj?.name ? obj?.name : "user",
    );
    const handleMediaClick = (media, itemIndex) => {
      if (media?.length) {
        const newMedia = media?.map?.((item) => {
          const isVideo = !!(
            item?.isVideo || item?.media_type?.toLowerCase() == "video"
          );
          return {
            url: item?.media_url,
            type: isVideo ? "video" : "image",
          };
        });
        setShowMediaPreviewer(true);
        setMedia(newMedia);
        setDefaultMediaIndex(itemIndex);
      }
    };

    // Hnadle media Grid styles
    const getGridSize = (length) => {
      if (length === 1) return 12;
      if (length === 2) return 6;
      return 4; // 3 or more elements, 33.33% width each
    };

    //check dataSources types
    let facebookManagedAccount =
      postData?.data_source == "FACEBOOK" &&
      headerData?.monitor_type == "MANAGED_PAGE";

    let igManagedAccount =
      postData?.data_source == "INSTAGRAM" &&
      headerData?.monitor_type == "MANAGED_ACCOUNT";

    return (
      <>
        <Box
          className={classNames(
            "posts-rep",
            isAgentResponseTarget ? "highlighted-post-reply" : "",
          )}
          ref={isAgentResponseTarget ? agentResponseTargetRef : null}
        >
          {/*
          This block of code is to add some line connector before the reply, we want to add this line connector in case of these conditions:
          the post must be a thread post and not the root post, and the post is not a missing reply post.
          */}
          {isThreadPost &&
          !isRootPost &&
          postType !== postTypes.missingReply ? (
            <Box
              className={classNames(
                "reply-line-connector",
                isAgentResponseTarget ? "highlighted-child" : "normal-child",
              )}
            >
              {isAgentResponseTarget ? (
                <Box className="reply-line-connector-text">
                  <FontAwesomeIcon icon={faMessageLines} />
                  <Typography>
                    {CheckValueLocale("replying_to_engagement", "", {}, intl)}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}
          {showMediaPreviewer ? (
            <MediaPreviewer
              showMediaPreviewer={showMediaPreviewer}
              setShowMediaPreviewer={setShowMediaPreviewer}
              media={media}
              defaultIndex={defaultMediaIndex}
            />
          ) : null}
          <Box className="posts-main-img">
            <div className="post-header-user">
              <img
                src={
                  obj?.profile_image_url ? obj?.profile_image_url : profileImg
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = profileImg;
                }}
              />
              <Box>
                <Box className="posts-main-user">{displayName}</Box>
                <Box className="post-date">
                  {handleFullDateTimeFormat(obj?.created_at)}
                </Box>
              </Box>
            </div>

            {obj?.tweet_link ? (
              <Tooltip
                title={
                  CheckValueLocale("open_on", "", {}, intl) +
                  CheckValueLocale(obj?.data_source, "", {}, intl)
                }
                placement="bottom"
                arrow
              >
                <Box className="posts-main-url">
                  <a href={obj?.tweet_link} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faExternalLink} />
                  </a>
                </Box>
              </Tooltip>
            ) : null}

            {/* Main post and Threads */}
            {mainPost ? (
              <EditAndDeleteButtons
                handleOpenDeleteModal={(e) =>
                  handleOpenDeleteModal(e, obj?.tweet_id, !!isRootPost)
                }
                setActiveReplyEditEngagement={setActiveReplyEditEngagement}
                deleteTooltip={"delete"}
                isDeletedResponse={isDeletedResponse}
                editTooltip={"edit"}
                isTweetLink={obj?.tweet_link}
                hideEditIcon={
                  !(
                    facebookManagedAccount &&
                    obj?.is_account_owner &&
                    authorizedToEditAndDelete
                  )
                }
                hideDeleteIcon={
                  !(
                    (authorizedToEditAndDelete && facebookManagedAccount) ||
                    (authorizedToEditAndDelete && igManagedAccount) ||
                    (authorizedToEditAndDelete &&
                      headerData?.product == "CXM" &&
                      (postData?.data_source == "TWITTER" ||
                        obj?.data_source_name) &&
                      (postData?.is_account_owner || obj?.is_account_owner))
                  )
                }
              />
            ) : null}

            {/* Replies */}
            {!mainPost && showEditDeleteBtns ? (
              <EditAndDeleteButtons
                handleOpenDeleteModal={(e) =>
                  handleOpenDeleteModal(
                    e,
                    obj?.isSocket
                      ? postData?.replies[0]?.item_id
                      : postData?.system_replies?.interaction_id,
                  )
                }
                setActiveReplyEditEngagement={setActiveReplyEditEngagement}
                deleteTooltip={"delete"}
                isDeletedResponse={isDeletedResponse}
                editTooltip={"edit"}
                isTweetLink={obj?.tweet_link}
                disabled={
                  statusItemCard === "complete" || !authorizedToEditAndDelete
                }
                isStatusComplete={statusItemCard === "complete"}
                hideEditIcon={
                  !(
                    postData?.data_source == "FACEBOOK" ||
                    !postData?.data_source == "TWITTER"
                  )
                }
                hideDeleteIcon={
                  !(
                    postData?.data_source == "FACEBOOK" ||
                    postData?.data_source == "TWITTER" ||
                    postData?.data_source == "INSTAGRAM"
                  )
                }
              />
            ) : null}
          </Box>
          <Box
            className={
              mainPost && !haveRep
                ? "main-post last-child no-rep"
                : haveRep
                  ? "main-post"
                  : "main-post last-child"
            }
          >
            <Box className="card-text-media">
              {obj?.media?.length && obj?.media[0] ? (
                <Box
                  className="cards-media"
                  minWidth={
                    obj?.media.length < 4
                      ? mediaSize * obj?.media.length + 5
                      : mediaSize * 2 + 5
                  }
                >
                  <Grid className="custom-mui-grid" container spacing={2}>
                    {obj?.media?.map((item, itemIndex) =>
                      itemIndex <= 1 || itemIndex === 2 ? (
                        <Grid
                          item
                          xs={getGridSize(Math.min(obj?.media?.length))}
                          key={itemIndex}
                          onClick={() =>
                            handleMediaClick(obj?.media, itemIndex)
                          }
                        >
                          {item?.media_url ? (
                            item?.isVideo ||
                            item?.media_type?.toLowerCase() === "video" ? (
                              <Box className="engagement-chat-message-media-video-container">
                                <video
                                  width={"100%"}
                                  height={200}
                                  controls={false}
                                  className="engagement-chat-message-media-video"
                                >
                                  <source
                                    src={item?.media_url}
                                    type="video/mp4"
                                  />
                                  <source
                                    src={item?.media_url}
                                    type="video/ogg"
                                  />
                                  <source
                                    src={item?.media_url}
                                    type="video/avi"
                                  />
                                  <img
                                    src={item?.media_url}
                                    alt="Video Thumbnail"
                                  />
                                </video>
                                {itemIndex === 2 && obj?.media?.length > 3 ? (
                                  <Box
                                    className="engagement-chat-message-media-max-reached"
                                    onClick={() =>
                                      handleMediaClick(obj?.media, itemIndex)
                                    }
                                  >
                                    <span>{`+${obj?.media?.length - 3}`}</span>
                                  </Box>
                                ) : (
                                  <Box className="play-button-container">
                                    <Box className="play-button">
                                      <FontAwesomeIcon icon={faPlay} />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            ) : (
                              <Box className="engagement-chat-message-media-image-wrapper">
                                <img
                                  className="engagement-chat-message-media-image"
                                  width={"100%"}
                                  height={200}
                                  src={item?.media_url}
                                  alt="Image Thumbnail"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = dummyImage;
                                  }}
                                />
                                {itemIndex === 2 && obj?.media?.length > 3 ? (
                                  <Box
                                    className="engagement-chat-message-media-max-reached"
                                    onClick={() =>
                                      handleMediaClick(obj?.media, itemIndex)
                                    }
                                  >
                                    <span>{`+${obj?.media?.length - 3}`}</span>
                                  </Box>
                                ) : null}
                              </Box>
                            )
                          ) : null}
                        </Grid>
                      ) : null,
                    )}
                  </Grid>
                </Box>
              ) : null}
              {/* Main post text */}
              {mainPost ? (
                <Box
                  className={isArabic(text) ? "post-ar" : "post-en"}
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedCard?.data_source === "TWITTER"
                        ? formatTextToHtml(sanitizeEngagementChatMessage(text)) // in public x platform only (posts & replies)
                        : sanitizeEngagementChatMessage(text),
                  }}
                />
              ) : null}
              {/* reply on post text */}
              {!mainPost && isReplyDeleted && !isReplied ? (
                deleteReplyMsg(postData)
              ) : !mainPost ? (
                <>
                  {activeReplyEditEngagement ? (
                    <EditReply
                      editReplyDisable={editReplyDisable}
                      maxChar={maxChar}
                      selectedCard={selectedCard}
                      postData={postData}
                      handleEditReply={() =>
                        handleEditReply(
                          obj?.isSocket
                            ? postData?.replies[0]?.item_id
                            : postData?.system_replies?.interaction_id,
                          mainPost,
                          obj,
                        )
                      }
                      btnLoading={btnLoading}
                      setActiveReplyEditEngagement={
                        setActiveReplyEditEngagement
                      }
                      setEditText={setEditText}
                      editText={editText}
                      onOpen={() =>
                        setEditText(
                          obj?.isSocket ? postData?.replies[0]?.text : editText,
                        )
                      }
                    />
                  ) : (
                    <>
                      {editTooltipActive ? (
                        <Box className={isArabic(text) ? "post-ar" : "post-en"}>
                          <Box
                            component="span"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeEngagementChatMessage(editText),
                            }}
                          />
                          <BootstrapTooltip
                            title={editDateTooltip(editTooltipDate)}
                            arrow
                            placement="top"
                            TransitionComponent={Zoom}
                          >
                            <Box component="span" className="edited-tooltip">
                              ({CheckValueLocale("edited", "", {}, intl)})
                            </Box>
                          </BootstrapTooltip>
                        </Box>
                      ) : (
                        <Box
                          className={isArabic(text) ? "post-ar" : "post-en"}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeEngagementChatMessage(text),
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              ) : null}
            </Box>
            {/* Translation */}
            <PostTranslation
              post={obj}
              translationActive={translationActive}
              setOpenTranslationCollapse={setOpenTranslationCollapse}
              openTranslationCollapse={openTranslationCollapse}
              setOpenReplyCollapse={setOpenReplyCollapse}
              openReplyCollapse={openReplyCollapse}
              translationButtonResult={translationButtonResult}
              setTranslationButtonResult={setTranslationButtonResult}
              translationButtonLoading={translationButtonLoading}
              setTranslationButtonLoading={setTranslationButtonLoading}
              translationStatus={translationStatus}
              setTranslationStatus={setTranslationStatus}
              translationSource={translationSource}
              translationTarget={translationTarget}
              text={text}
              selectedCard={selectedCard}
              postId={postReplyId}
              stats={stats}
              setStats={setStats}
            />
            {!obj?.isSocket && !isRootPost ? (
              <Box className="cards-footer">
                {(isReplyDeleted || activeReplyEditEngagement) &&
                !mainPost ? null : mainPost || !mainPost ? (
                  <Box className="card-reacts">
                    <Box className={postData?.data_source?.toLowerCase()}>
                      {postData?.data_source?.toLowerCase() == "facebook" ? (
                        <img src={reacts} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}

                      {reactsCount}
                    </Box>
                    <Box>
                      <img src={replay} />
                      {obj?.reply_count
                        ? `${obj?.reply_count}`
                        : obj?.total_comments
                          ? `${obj?.total_comments}`
                          : "0"}
                    </Box>
                  </Box>
                ) : null}
                <ThemesAndSentiments
                  replyId={postReplyId}
                  handleChangeInteraction={handleChangeInteraction}
                  selectedInteractions={selectedInteractions}
                  interactionLoading={interactionLoading}
                  sentimentsOptions={sentimentsOptions}
                  subthemesOptions={themesOptions}
                  themesOptions={categoriesOptions}
                  showThemes={expandedThemes?.[postReplyId]}
                  toggleShowThemes={() => toggleThemesExpansion(postReplyId)}
                  mainPost={mainPost}
                  isThreadPost={isThreadPost}
                />
                {!mainPost && activeReplyEditEngagement ? (
                  <Box className="delete-msg-wrapper">
                    <Box className="delete-msg-reply-by">
                      {`${CheckValueLocale("replied_by", "", {}, intl)}: `}
                      <Box component="span">
                        {postData?.system_replies?.replier_info?.email}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Box>
        </Box>
        {!obj?.isSocket && isRootPost ? (
          <Box className="cards-footer">
            {(isReplyDeleted || activeReplyEditEngagement) &&
            !mainPost ? null : mainPost || !mainPost ? (
              <Box className="card-reacts">
                <Box className={postData?.data_source?.toLowerCase()}>
                  {postData?.data_source?.toLowerCase() == "facebook" ? (
                    <img src={reacts} />
                  ) : (
                    <FavoriteBorderIcon />
                  )}

                  {reactsCount}
                </Box>
                <Box>
                  <img src={replay} />
                  {obj?.reply_count
                    ? `${obj?.reply_count}`
                    : obj?.total_comments
                      ? `${obj?.total_comments}`
                      : "0"}
                </Box>
              </Box>
            ) : null}
            <ThemesAndSentiments
              replyId={postReplyId}
              handleChangeInteraction={handleChangeInteraction}
              selectedInteractions={selectedInteractions}
              interactionLoading={interactionLoading}
              sentimentsOptions={sentimentsOptions}
              subthemesOptions={themesOptions}
              themesOptions={categoriesOptions}
              showThemes={expandedThemes?.[postReplyId]}
              toggleShowThemes={() => toggleThemesExpansion(postReplyId)}
              mainPost={mainPost}
              isThreadPost={isThreadPost}
            />
            {!mainPost && activeReplyEditEngagement ? (
              <Box className="delete-msg-wrapper">
                <Box className="delete-msg-reply-by">
                  {`${CheckValueLocale("replied_by", "", {}, intl)}: `}
                  <Box component="span">
                    {postData?.system_replies?.replier_info?.email}
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Box>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    if (
      !(
        selectedCard.system_replies.is_replied === 1 ||
        headerData?.paused === 1 ||
        isReplied
      ) &&
      (savedSuggTab || aiRepsTab)
    ) {
      handelOpenSidebar();
    }
  }, [selectedCard, headerData, isReplied, savedSuggTab, aiRepsTab]);

  useEffect(() => {
    let status_logs = selectedCard?.system_replies?.status_logs?.length
      ? selectedCard?.system_replies?.status_logs?.sort(function (a, b) {
          return new Date(a?.created_at) - new Date(b?.created_at);
        })
      : [];
    setPostData({
      ...selectedCard,
      system_replies: {
        ...selectedCard?.system_replies,
        status_logs: status_logs?.length ? [...status_logs] : [],
      },
      replies:
        selectedCard?.replies?.length && !isEmptyObj(selectedCard?.replies[0])
          ? [...selectedCard?.replies]
          : postData?.replies?.length
            ? postData?.replies
            : selectedCard?.system_replies?.replier_info &&
                !isEmptyObj(selectedCard?.system_replies?.replier_info)
              ? [{ ...selectedCard?.system_replies?.replier_info }]
              : [],
    });
  }, [selectedCard]);

  const dateWithTimeZone = (date) => {
    let unixDate = moment(date).unix();
    let intialDate = moment
      .unix(unixDate)
      .format("YYYY/MM/DD hh:mm a")
      .split(" ");
    let dateText = `${intialDate[0]} - ${intialDate[1]} ${CheckValueLocale(
      intialDate[2] == "pm" ? "hashtag_period_pm" : "hashtag_period_am",
      "",
      {},
      intl,
    )} ${
      companyTimeZone >= 0
        ? `(${CheckValueLocale("gmt", "", {}, intl)} +${companyTimeZone})`
        : `(${CheckValueLocale("gmt", "", {}, intl)} ${companyTimeZone})`
    }`;
    return dateText;
  };

  const [value, setVal] = useState(2);
  const [tipValue, setTipValue] = useState(2);
  const valueTooltip = [];
  useEffect(() => {
    setTranslationStatus({});
    setOpenTranslationCollapse({});
    setTranslationButtonLoading({});
    setTranslationButtonResult({});
  }, [translationTarget, translationSource]);

  const mainIndexPost = -1;

  // handle active log name

  const handleActiveLogName = (logname) => {
    const showName =
      logname === "automated_public_reply"
        ? CheckValueLocale("activity_automated_public_reply", "", {}, intl)
        : logname;
    return showName;
  };

  const shouldDisplayMissingDataInReplyThread =
    postData?.has_next_level || postData?.thread_request_historical;

  const isMonitorPaused = headerData?.paused === 1;
  const deletePopUpBody = () => {
    return (
      <>
        <Box className="delete-title" component={"h4"}>
          {CheckValueLocale("delete_reply_body", "", {}, intl)}
        </Box>
        <Box className="delete-des" component={"strong"}>
          {CheckValueLocale("delete_reply_cannot_be_undone", "", {}, intl)}
        </Box>
      </>
    );
  };
  return (
    <Box className="main-engagment-posts">
      <Box
        className={
          selectedCard.system_replies.is_replied === 1 ||
          headerData?.paused === 1 ||
          isReplied
            ? "main-posts-contant no-rep"
            : `main-posts-contant ${!openSidebar ? "max-padding" : ""}`
        }
      >
        <Box className="main-contant">
          {/*
           We have three section four main section here in posts and replires component.
           1- Root showing, for now we are only showing the root of the thread post only for twitter.
           in case it's a thread post(twitter for now), if root exists we will show it other wise we will show
           the missing reply component which is responsible for requesting it from the server.
          */}
          {postData?.tweet_id && isThreadPost ? (
            isRootMissing ? (
              <Box className="main-parent-post post-root">
                <MissingReply
                  type={postTypes.missingReply}
                  isRoot
                  isLoading={missingReplyLoadingType === postTypes.root}
                  disableRequest={
                    missingReplyLoadingType === postTypes.reply ||
                    isMonitorPaused
                  }
                  isMonitorPaused={isMonitorPaused}
                  getHistoricalRequestInteraction={handleHistoricalRequest}
                />
                <Box className="cards-footer empty-cards-footer" />
              </Box>
            ) : (
              <Box className="main-parent-post post-root">
                {posts(postData?.root_interaction, 0, true, postTypes.root)}
              </Box>
            )
          ) : null}
          {/*
            2- In case it's a thread post we will check if there missing replies between the root and the first reply,
            if there are missing replies we will show the missing reply component which is responsible for requesting it from the server.
          */}
          {shouldDisplayMissingDataInReplyThread ? (
            <MissingReply
              type={
                postData?.has_next_level
                  ? postTypes.loadMoreReplies
                  : postTypes.missingReply
              }
              disableRequest={
                missingReplyLoadingType === postTypes.root || isMonitorPaused
              }
              disablePagination={missingReplyLoadingType === postTypes.root}
              onLoadMore={handleOnLoadMore}
              isLoading={missingReplyLoadingType === postTypes.reply}
              getHistoricalRequestInteraction={handleHistoricalRequest}
              isMonitorPaused={isMonitorPaused}
            />
          ) : null}
          {/*
            3- we will always loop over the threadReplies to render any existing replies in the thread, it doesn't matter if it's a thread post or not.
          */}
          {threadReplies?.map?.((reply, index) => {
            const notLast = index !== threadReplies?.length - 1;
            return (
              <Box
                className={classNames(
                  "main-middle-post",
                  postData?.replies?.length ? "middle-reply-style" : "",
                  notLast ? "not-last-reply" : "",
                )}
                key={reply?.tweet_id}
              >
                {posts(
                  reply,
                  postData?.replies?.length || notLast,
                  true,
                  index === 0 &&
                    shouldDisplayMissingDataInReplyThread &&
                    reply?.tweet_id !== selectedCard?.tweet_id
                    ? postTypes?.missingReply
                    : postTypes.reply,
                )}
              </Box>
            );
          })}

          {/*
          4- last section in the component is to render our agents replies in case it's a thread post and the agent replied to the thread.
          */}
          {postData?.replies?.map?.((item, index) => {
            return (
              <Box
                key={index}
                className="main-post-tree"
                onMouseEnter={() => setShowEditDeleteBtns(true)}
                onMouseLeave={() => setShowEditDeleteBtns(!showEditDeleteBtns)}
              >
                {posts(
                  item,
                  item?.replies?.length,
                  false,
                  postTypes.agentResponse,
                )}
                {item?.replies?.map?.((itemChild) => {
                  return (
                    <Box className="main-border-tree">
                      {postData?.replies?.length - 1 != index && (
                        <Box className="border-tree"></Box>
                      )}
                      <Box className="main-post-tree">
                        {posts(
                          itemChild,
                          itemChild?.replies?.length,
                          false,
                          postTypes.agentResponse,
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
          {postData?.system_replies?.status_logs?.length ? (
            postData?.system_replies?.status_logs[
              postData?.system_replies?.status_logs?.length - 1
            ]?.status == "complete" ? (
              <Box className="main-post-state main-post-with-space">
                <Box>
                  {CheckValueLocale(
                    "marked_as_completed",
                    "",
                    {
                      name: handleActiveLogName(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.user_email,
                      ),
                      date: dateWithTimeZone(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.created_at,
                      ),
                    },
                    intl,
                  )}
                  {postData?.system_replies?.completion_reason == "other" ? (
                    <Box
                      component={"span"}
                      className="completion-reason-replies"
                    >
                      {`- ${CheckValueLocale(
                        postData?.system_replies?.completion_reason,
                        "",
                        {},
                        intl,
                      )}`}{" "}
                      {`(${postData?.system_replies?.other_reason}) `}
                    </Box>
                  ) : (
                    ` - ${CheckValueLocale(
                      postData?.system_replies?.completion_reason,
                      "",
                      {},
                      intl,
                    )} `
                  )}
                </Box>
              </Box>
            ) : postData?.system_replies?.status_logs[
                postData?.system_replies?.status_logs?.length - 1
              ]?.status == "active" ? (
              <Box className="main-post-state main-post-with-space">
                <Box>
                  {CheckValueLocale(
                    "marked_as_active",
                    "",
                    {
                      name: handleActiveLogName(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.user_email,
                      ),
                      date: dateWithTimeZone(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.created_at,
                      ),
                    },
                    intl,
                  )}
                </Box>
              </Box>
            ) : postData?.system_replies?.status_logs[
                postData?.system_replies?.status_logs?.length - 1
              ]?.status == "reopen" ? (
              <Box className="main-post-state main-post-with-space">
                <Box>
                  {CheckValueLocale(
                    "marked_as_reopen",
                    "",
                    {
                      name: postData?.system_replies?.status_logs[
                        postData?.system_replies?.status_logs?.length - 1
                      ]?.user_email,
                      date: dateWithTimeZone(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.created_at,
                      ),
                    },
                    intl,
                  )}
                </Box>
              </Box>
            ) : null
          ) : null}
        </Box>
      </Box>
      {statusItemCard === "complete" ||
      headerData?.paused === 1 ||
      postData?.replies?.length ? (
        <Box className="main-posts-footer no-reply-footer">
          <Box className="main-no-reply">
            <Box
              className={
                headerData?.paused === 1
                  ? "main-sent-reply paused"
                  : "main-sent-reply"
              }
            >
              {headerData?.paused === 1 ? (
                <ErrorOutlineIcon />
              ) : (
                <CheckCircleIcon />
              )}
              {CheckValueLocale(
                headerData?.paused !== 1
                  ? statusItemCard === "complete"
                    ? "mark_completed"
                    : "reply_sent"
                  : headerData?.type == "monitor"
                    ? "paused_monitors_header"
                    : "paused_channels_header",
                "",
                {},
                intl,
              )}
            </Box>
            <Typography className="no-reply-class">
              {CheckValueLocale(
                headerData?.paused !== 1
                  ? statusItemCard === "complete"
                    ? "mark_completed_msg"
                    : "no_reply_text"
                  : headerData?.type == "monitor"
                    ? "paused_monitors_contant"
                    : "paused_channels_contant",
                "",
                { name: headerData?.monitor_name },
                intl,
              )}
              {headerData?.paused == 1 ? (
                <Link
                  target="_blank"
                  to={
                    headerData?.type == "monitor"
                      ? "/social/monitor_list"
                      : "/manage-channels/list"
                  }
                  className="paused-rediraction"
                >
                  {CheckValueLocale(
                    headerData?.type == "monitor"
                      ? "monitors_list"
                      : "manage_channels",
                    "",
                    {},
                    intl,
                  )}
                  <FontAwesomeIcon icon={faExternalLink} />
                </Link>
              ) : null}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box className="main-posts-footer-parant">
            {!openSidebar && authorizedToEditAndDelete ? (
              <Button
                id="engagements-ai-suggestions-btn"
                className="ai-suggestions"
                onClick={() => handelOpenSidebar()}
              >
                <img src={ezgif} />
                {CheckValueLocale("ai_suggestions", "", {}, intl)}
              </Button>
            ) : null}
            <ReplyModal
              setSelectedCardIteractionID={setSelectedCardIteractionID}
              setShowSuccessModal={setShowSuccess}
              showSuccessModal={showSuccess}
              selectedCard={selectedCard}
              setPostData={setPostData}
              postData={postData}
              headerData={headerData}
              setIsReplied={setIsReplied}
              setIsDeletedResponse={setIsDeletedResponse}
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setOpenSlaSideBar={setOpenSlaSideBar}
              text={text}
              setText={setText}
              setOpenSidebar={setOpenSidebar}
              checkAiSuggestions={checkAiSuggestions}
              setCheckAiSuggestions={setCheckAiSuggestions}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              translationActive={translationActive}
              setTranslationActive={setTranslationActive}
              translationSource={translationSource}
              setTranslationSource={setTranslationSource}
              translationTarget={translationTarget}
              setTranslationTarget={setTranslationTarget}
              stats={stats}
              setStats={setStats}
              languagesArraySource={languagesArraySource}
              languagesArrayTarget={languagesArrayTarget}
              translateStatus={translateStatus}
              setTranslateStatus={setTranslateStatus}
              openSidebar={openSidebar}
              openDetailsSideBarReplay={openDetailsSideBarReplay}
              isSidebarsOpened={isSidebarsOpened}
              showAllIcon={showAllIcon}
              aiAgentList={aiAgentList}
              setRefresh={setRefresh}
              setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
              setSnackBarDataAiAgent={setSnackBarDataAiAgent}
              setInjectedMessage={setInjectedMessage}
              aiAgentId={aiAgentId}
              replyBoxInputTextRef={replyBoxInputTextRef}
              reduxSlaKeys={slaKey}
              itemSelected={itemSelected}
              socket={socket}
            />
          </Box>
        </>
      )}
      {/* Delete Modal Popup */}
      <PopupModal
        title={CheckValueLocale("delete_reply", "", {}, intl)}
        body={deletePopUpBody()}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete", "", {}, intl)}
        open={openDeleteModalStats?.openDeleteModal}
        close={handleCloseDeleteModal}
        disabled={disabledDeleteBtn}
        accept={() => handleDeleteReply(openDeleteModalStats?.id)}
        rightColor="error"
        warning
        minWidth="600px"
        addClasses="delete-engagement-buttons"
        classeName="delete-engagement-buttons-engagements"
      />

      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        severity={snackBarData?.severity}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
      />
    </Box>
  );
};

export default PostsAndReplies;
