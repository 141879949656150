import Services from "./Services";
import { api } from "./newServices";

var Engagements = {};

Engagements.getDatsourcesValidations = (queryData) => {
  return api.get("api/v4/engagements/validations", { params: queryData });
};

Engagements.getCollections = (queryData) => {
  return api.get("api/v5/engagements/saved_replies/collections", {
    params: queryData,
  });
};

Engagements.getAllCollections = (queryData) => {
  return api.get(
    "api/v5/engagements/saved_replies/collections/company_collections",
    {
      params: queryData,
    },
  );
};

Engagements.getReplaies = (queryData) => {
  return api.get(
    `api/v5/engagements/saved_replies/collections/${queryData?.id}/saved_replies`,
    {
      params: queryData,
    },
  );
};

Engagements.createReplay = (queryData) => {
  return api.post(`api/v5/engagements/saved_replies`, queryData);
};

Engagements.moveReplaies = (queryData) => {
  return api.post(
    `api/v5/engagements/saved_replies/collections/${queryData?.id}/bulk_move_saved_replies`,
    queryData,
  );
};

Engagements.delBulkReplay = (queryData) => {
  return api.delete(
    `api/v5/engagements/saved_replies/collections/${queryData?.id}/bulk_delete_saved_replies`,
    {
      data: queryData,
    },
  );
};

Engagements.createCollection = (queryData) => {
  return api.post(`api/v5/engagements/saved_replies/collections`, queryData);
};

Engagements.editCollection = (queryData) => {
  return api.put(
    `api/v5/engagements/saved_replies/collections/${queryData?.id}`,
    queryData,
  );
};

Engagements.delCollection = (queryData) => {
  return api.delete(
    `api/v5/engagements/saved_replies/collections/${queryData?.id}`,
    {
      data: queryData,
    },
  );
};

Engagements.delReplay = (queryData) => {
  return api.delete(`api/v5/engagements/saved_replies/${queryData?.id}`, {
    data: queryData,
  });
};

Engagements.editReplay = (queryData) => {
  return api.put(
    `api/v5/engagements/saved_replies/${queryData?.id}`,
    queryData,
  );
};

Engagements.bulkComplete = (queryData) => {
  return api.post("api/v5/engagements/batch_complete_interactions", queryData);
};
Engagements.bulkAssign = (queryData) => {
  return api.post("api/v5/engagements/batch_assign_interactions", queryData);
};

Engagements.getSurveyCharacterLimits = (queryData) => {
  return api.get("api/v5/engagements/get_character_limits", {
    params: queryData,
  });
};

Engagements.getSurveyPredefinedOptions = (queryData) => {
  return api.get("api/v5/engagements/csat_predefined_questions", {
    params: queryData,
  });
};

Engagements.getSurveySelectedData = (queryData) => {
  return api.get("api/v5/engagements/get_engagement_surveys", {
    params: queryData,
  });
};

Engagements.getSurveyCsatOptions = (queryData) => {
  return api.get("api/v5/engagements/csat-options", {
    params: queryData,
  });
};

Engagements.getEngagementsData = (queryData, cancelToken) => {
  return Services.getData(
    "api/v4/engagements/data",
    queryData,
    undefined,
    cancelToken,
  );
};

Engagements.getSavedMonitors = (queryData) => {
  return Services.getData("api/v4/engagements/saved_monitors", queryData);
};

Engagements.getSingleInteraction = (queryData, cancelToken) => {
  return Services.getData(
    "api/v4/engagements/single/interaction",
    queryData,
    undefined,
    cancelToken,
  );
};

Engagements.getSingleInteractionV5 = (queryData, cancelToken) => {
  return Services.getData(
    "api/v5/engagements/single/interaction",
    queryData,
    undefined,
    cancelToken,
  );
};
Engagements.getHistoricalRequestInteraction = (queryData) => {
  return Services.postData("api/v5/engagements/historical_request", queryData);
};

Engagements.getInteractionDmHistory = (queryData) => {
  return Services.getData("api/v4/engagements/dm_history", queryData);
};

Engagements.getSingleUserProfileData = (queryData, cancelToken) => {
  return Services.getData(
    "api/v4/engagements/profile_data",
    queryData,
    undefined,
    cancelToken,
  );
};

Engagements.editInteractions = (queryData) => {
  return Services.putData(`api/v4/engagements/edit_interactions`, queryData);
};

Engagements.putSavedMonitors = (queryData, product_id) => {
  return Services.putData(
    `api/v4/engagements/saved_monitors?product_id=${product_id}`,
    queryData,
  );
};

Engagements.getMonitorsAndChannels = (queryData) => {
  return Services.getData("api/v4/engagements/company_monitors", queryData);
};

Engagements.getCompletionReasons = (queryData) => {
  return Services.getData(
    "api/v4/engagements/completion_reason_tooltips",
    queryData,
  );
};

Engagements.enabledFilters = (queryData) => {
  return Services.getData("api/v4/engagements/enabled_filters", queryData);
};
Engagements.updateStatus = (queryData) => {
  return Services.putData(`api/v4/engagements/update_status`, queryData);
};
Engagements.getSingleProfileData = (queryData) => {
  return Services.getData(`api/v4/engagements/profile_data`, queryData);
};
Engagements.getCalendarData = (queryData) => {
  return Services.getData("api/v4/engagements/scheduled_posts", queryData);
};

//publish
Engagements.getPublishMediaValidations = (queryData) => {
  return Services.getData(
    "api/v4/engagements/scheduled_posts/validations",
    queryData,
  );
};
Engagements.uploadFile = (queryData) => {
  return Services.postData(
    `/api/v4/engagements/upload/company_info`,
    queryData,
  );
};

Engagements.createPostPublish = (queryData) => {
  return Services.postData("api/v4/engagements/scheduled_posts", queryData);
};

Engagements.deleteEngagementPost = (
  id,
  dataSourceId,
  monitorId,
  item_type,
  product_id,
  account_identifier,
  parent_id,
) => {
  return Services.deleteData(
    `api/v4/engagements/published_posts/${id}?data_source_id=${dataSourceId}&monitor_model_id=${monitorId}&item_type=${item_type}&product_id=${product_id}${account_identifier ? `&account_identifier=${account_identifier}` : ""}${parent_id ? `&parent_id=${parent_id}` : ""}`,
  );
};
Engagements.getAiReplaies = (queryData) => {
  return Services.getData("api/v4/engagements/generate_responses", queryData);
};

Engagements.editUserInfo = (queryData) => {
  return Services.putData(`api/v4/engagements/settings`, queryData);
};

Engagements.getLookupMessages = () => {
  return Services.getData("api/v4/lookup_messages");
};

Engagements.getUserInfo = (queryData) => {
  return Services.getData(`api/v4/engagements/settings`, queryData);
};

Engagements.enabledFiltersPublish = (queryData) => {
  return Services.getData(
    "api/v4/engagements/scheduled_posts/enabled_filters",
    queryData,
  );
};

Engagements.getAiSuggestions = (queryData) => {
  return Services.postData(
    "api/v4/engagements/get_custom_responses",
    queryData,
  );
};
Engagements.getAiSavedResponses = (queryData) => {
  return Services.postData("api/v4/engagements/get_saved_responses", queryData);
};
Engagements.deleteReply = (queryData) => {
  return Services.deleteData(
    `api/v4/engagements/saved_replies/${queryData?.id}`,
    queryData,
  );
};
Engagements.getSavedLanguage = (queryData) => {
  return Services.getData("api/v4/user/get_translation_lang", queryData);
};
Engagements.saveTranslationLanguage = (queryData) => {
  return Services.postData("api/v4/user/save_translation_lang", queryData);
};
Engagements.detectTranslate = (queryData) => {
  return Services.getData("api/v4/engagements/detect_translate", queryData);
};
Engagements.addRate = (queryData) => {
  return Services.postData(
    "api/v4/engagements/save_rating_translation",
    queryData,
  );
};
//get upcoming data
Engagements.getUpcomingData = (queryData) => {
  return Services.getData("api/v4/engagements/scheduled_posts", queryData);
};

Engagements.showDetailsPublish = (id, queryData) => {
  return Services.getData(
    `api/v4/engagements/scheduled_posts/${id}`,
    queryData,
  );
};

Engagements.EditScheduledPost = (queryData, id) => {
  return Services.putData(
    `api/v4/engagements/scheduled_posts/${id}`,
    queryData,
  );
};

Engagements.editPostPublished = (queryData) => {
  return Services.postData("api/v4/engagements/published_posts", queryData);
};
Engagements.deleteScheduledPost = (id, queryData) => {
  return Services.deleteData(
    `api/v4/engagements/scheduled_posts/${id}`,
    queryData,
  );
};
Engagements.deletePublishedPost = (queryData) => {
  return Services.deleteData(
    `api/v4/engagements/published_posts/${queryData?.id}?data_source_id=${queryData?.data_source_id}&reference_id=${queryData?.reference_id}&product_id=${queryData?.product_id}`,
  );
};

Engagements.editReply = (queryData) => {
  return Services.postData("api/v4/engagements/published_posts", queryData);
};

// New Conversation Message [ DM ]
Engagements.getAllDMAuthrizedAccounts = (queryData) => {
  return Services.getData(
    "api/v4/engagements/dm_authorized_accounts",
    queryData,
  );
};
Engagements.getDMMediaValidations = (product_id) => {
  return Services.getData(
    `api/v4/engagements/validations?product_id=${product_id}&type=direct_message`,
  );
};
Engagements.getSenderUserList = (
  product_id,
  data_source_name,
  page_number,
  query,
) => {
  return Services.getData(
    `api/v4/engagements/users_list?product_id=${product_id}&data_source_name=${data_source_name}&page_number=${page_number}&query=${query}`,
  );
};

Engagements.getLastUsedAccount = (queryData) => {
  return Services.getData(`api/v4/engagements/last_used_account`, queryData);
};
Engagements.getEngagementsAnalyticsCredentials = (queryData) => {
  return Services.getData(
    `api/v4/engagements/analytics/private_data`,
    queryData,
  );
};
Engagements.CheckEngageMentAnalytics = (queryData) => {
  return Services.getData(
    `api/v4/engagements/analytics/check_engagement_analytics`,
    queryData,
  );
};
Engagements.updateUserAgentStatus = (queryData) => {
  return Services.postData(`api/v4/user/agent_info`, queryData);
};
Engagements.getEnagementConditions = (queryData) => {
  return Services.getData(`api/v4/engagements/conditions`, queryData);
};
Engagements.checkNameExistence = (queryData) => {
  return Services.getData(
    `/api/v4/engagements/inbound_routings/check_name_existence`,
    queryData,
  );
};
Engagements.getRoutingAlgorithms = (queryData) => {
  return Services.getData(
    `api/v4/engagements/inbound_routings/auto_assign_algorithms`,
    queryData,
  );
};
Engagements.getCompanySLAs = (queryData) => {
  return Services.getData(`api/v4/engagements/slas`, queryData);
};
Engagements.createInboundRouting = (queryData) => {
  return Services.postData(`api/v4/engagements/inbound_routings`, queryData);
};
Engagements.getAgentCapacities = (queryData) => {
  return Services.getData(`api/v4/engagements/agent_capacities`, queryData);
};
Engagements.updateAgentCapacities = (queryData) => {
  return Services.postData(`api/v4/engagements/agent_capacities`, queryData);
};
Engagements.createSLA = (queryData) => {
  return Services.postData(`api/v4/engagements/slas`, queryData);
};
Engagements.getInboundRoutings = (queryData) => {
  return Services.getData(`api/v4/engagements/inbound_routings`, queryData);
};

Engagements.deleteInboundRouting = (id) => {
  return Services.deleteData(`api/v4/engagements/inbound_routings/${id}`);
};
Engagements.updateRoutingPriority = (id, queryData) => {
  return Services.putData(
    `api/v4/engagements/inbound_routings/${id}/priority`,
    queryData,
  );
};

Engagements.getInboundRouting = (id) => {
  return Services.getData(`api/v4/engagements/inbound_routings/${id}`);
};
Engagements.updateInboundRouting = (id, queryData) => {
  return Services.putData(
    `api/v4/engagements/inbound_routings/${id}`,
    queryData,
  );
};

Engagements.getUsers = (queryData) => {
  return Services.getData("api/v4/engagements/informal/users/", queryData);
};

Engagements.getCsatFeedback = (queryData) => {
  return Services.getData(
    "api/v4/engagements/analytics/v2/engagement_survey_feedback",
    queryData,
  );
};

Engagements.getTeamUsers = (queryData) => {
  return Services.getData("api/v4/engagements/informal/team_users/", queryData);
};

Engagements.assignTeamsAndUsers = (queryData) => {
  return Services.postData("api/v4/engagements/manual_assign", queryData);
};
Engagements.getEngagementAnalyticsData = (tabName, queryData) => {
  return Services.getData(
    `api/v4/engagements/analytics/v2/${tabName}`,
    queryData,
  );
};
Engagements.getKnowledgeBase = (queryData) => {
  return Services.getData("api/v4/engagements/knowledge_base", queryData);
};
Engagements.engagementsExport = (queryData) => {
  return api.post("api/v5/engagements/export", queryData);
};
Engagements.createNote = (queryData) => {
  return api.post("api/v6/engagements/notes", queryData);
};
Engagements.getNotesUsersList = (queryData) => {
  return api.get("api/v6/engagements/notes/get_notes_users_list", {
    params: queryData,
  });
};
Engagements.getNotesList = (queryData) => {
  return api.get("api/v6/engagements/notes", { params: queryData });
};
Engagements.deleteNote = (queryData, id) => {
  return api.delete(`api/v6/engagements/notes/${id}`, { data: queryData });
};
Engagements.updateNote = (queryData, id) => {
  return api.put(`api/v6/engagements/notes/${id}`, queryData);
};

// Engagement Tags Api ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Engagements.getEngagementTags = (queryData) => {
  return api.get("api/v5/engagements/tags", { params: queryData });
};
Engagements.addEngagementTag = (queryData) => {
  return api.post("api/v5/engagements/tags", queryData);
};
Engagements.updateStatusTags = (id, queryData) => {
  return api.patch(`api/v5/engagements/tags/${id}/toggle_status`, queryData);
};
Engagements.getInformalTags = (queryData) => {
  return api.get(`api/v4/engagements/informal/tags`, queryData);
};
Engagements.applyTags = (queryData) => {
  return api.post(`api/v5/engagements/tags/apply`, queryData);
};
Engagements.getColorTags = (queryData) => {
  return api.get(`api/v4/engagements/informal/tags_colors`, queryData);
};
Engagements.editTag = (id, queryData) => {
  return api.put(`api/v5/engagements/tags/${id}`, queryData);
};
Engagements.deleteTag = (id) => {
  return api.delete(`api/v5/engagements/tags/${id}`);
};

Engagements.sendReplyDataV5 = (queryData) => {
  return Services.postData("api/v5/engagements", queryData);
};

Engagements.sendReplyDataV4 = (queryData) => {
  return Services.postData("api/v4/engagements", queryData);
};
Engagements.uploadMediaEngagement = (queryData) => {
  return Services.postData("api/v5/engagements/upload/media", queryData);
};

// Engagement Tags Api ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export default Engagements;
