import { useEffect } from "react";
import { Box, Button, Typography, Divider, Avatar } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CloseIcon from "@mui/icons-material/Close";
import "./publishPopup.scss";
import "./detailsPopup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEllipsisV,
  faExternalLink,
  faHourglassStart,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faExclamationCircle,
  faLink,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import XPIcon from "images/engagements-images/xp-icon-eng-round.svg";
import FBIcon from "images/engagements-images/fb-round-icon.svg";
import IGIcon from "images/engagements-images/ig-round-icon.svg";
import IGPublishIcon from "images/engagements-images/ig-publish-icon.svg";
import ImagePlaceholder from "../../../../../../../../images/shared-images/placeholder-for-broken-image.png";
import moment from "moment";
import {} from "@fortawesome/free-brands-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import PopupModal from "components/popupModal";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar.js";
import xPlatform from "images/social-icons/x-platform-icon.svg";

const ITEM_HEIGHT = 48;

const DetailsPopup = ({
  setPublishTypeEvent,
  selectedEventDetails,
  getDetailsPopupPublish,
  idPublishedShow,
  handleClose,
  setSelectedEventDetails,
  accountsPublishSelected,
  childProps,
  allawedAccountData,
  getCalendarData,
  assignActiveFilters,
  selectedAccoutnts,
  date,
  handleDateFormat,
  delPopup,
  setDelPopup,
}) => {
  const haveAccountAccess = allawedAccountData?.filter(
    (item) =>
      +selectedEventDetails?.attributes?.account_info_reference_id ===
        +item?.attributes?.id &&
      selectedEventDetails?.attributes?.data_source_name?.toLowerCase() ===
        item?.attributes?.data_source?.toLowerCase(),
  );

  const [copyId, setCopyId] = useState(false);
  let { startDate, endDate } = handleDateFormat(date);
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    if (selectedEventDetails?.attributes?.status !== "published") {
      EngagementsController.deleteScheduledPost(
        selectedEventDetails?.attributes?.id,
        window?.localStorage?.engagements_id,
      ).then((data) => {
        if (data?.errorMsg) {
          setDelPopup({
            open: false,
            loading: false,
          });
          setSnackBar({
            open: true,
            title: "something_wrong",
            message: "please_try_again",
            severity: "error",
          });
        } else {
          getCalendarData(
            startDate,
            endDate,
            assignActiveFilters,
            selectedAccoutnts,
          );
        }
      });
    } else {
      let qurData = {
        id: selectedEventDetails?.attributes?.post_id,
        data_source_id: selectedEventDetails?.attributes?.data_source_id,
        reference_id:
          selectedEventDetails?.attributes?.account_info_reference_id,
        product_id: window?.localStorage?.engagements_id,
      };
      EngagementsController.deletePublishedPost(qurData).then((data) => {
        if (data?.errorMsg) {
          setDelPopup({
            open: false,
            loading: false,
          });
          setSnackBar({
            open: true,
            title: "something_wrong",
            message: "please_try_again",
            severity: "error",
          });
        } else {
          getCalendarData(
            startDate,
            endDate,
            assignActiveFilters,
            selectedAccoutnts,
          );
        }
      });
    }
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };
  const delBody = () => {
    return (
      <Box className="main-del-popup" id="main-del-popup-calendar">
        <Box className="del-popup-header">
          {CheckValueLocale(
            "del_from_calend",
            "",
            {
              datasource: CheckValueLocale(
                eventData?.data_source_name?.toLowerCase(),
                "",
                {},
                intl,
              ),
            },
            intl,
          )}
        </Box>
        <Box className="del-popup-contant">
          <Box className="about-del">
            <Box>{CheckValueLocale("sure_del", "", {}, intl)}</Box>
          </Box>
          <Box>{CheckValueLocale("un_done", "", {}, intl)}</Box>
        </Box>
        <Box className="del-popup-footer">
          <Button
            className="cancel-rep-btn"
            disabled={delPopup?.loading}
            onClick={() =>
              setDelPopup({
                open: false,
                loading: false,
              })
            }
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </Button>
          <Button
            disabled={delPopup?.loading}
            className="del-rep-btn"
            onClick={() => {
              setDelPopup({
                ...delPopup,
                loading: true,
              });
              handleDelete();
            }}
          >
            {delPopup?.loading ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              CheckValueLocale("yes_del", "", {}, intl)
            )}
          </Button>
        </Box>
      </Box>
    );
  };
  const handleDatasourcesIcon = (datasource) => {
    let datasourcesLIst = {
      twitter: XPIcon,
      facebook: FBIcon,
      instagram: IGIcon,
      default: XPIcon,
    };
    return datasourcesLIst[datasource] || datasourcesLIst.default;
  };
  const handleIconDataSources = (datasource) => {
    let datasourcesLIst = {
      facebook: faFacebookF,
      // instagram: faInstagram,
    };
    return datasourcesLIst[datasource] || datasourcesLIst.default;
  };
  const handleCheckstatus = (checkStatus) => {
    let datasourcesLIst = {
      published: faCheckCircle,
      scheduled: faHourglassStart,
      failed: faExclamationCircle,
    };
    return datasourcesLIst[checkStatus];
  };
  const getSocialLink = (type, name) => {
    const SocialIcons = {
      facebook: "https://www.facebook.com/" + name,
      twitter: "https://twitter.com/" + name,
      instagram: "https://www.instagram.com/" + name,
    };
    return SocialIcons[type];
  };
  const handleCopyId = () => {
    navigator.clipboard.writeText(eventData?.id);
    setCopyId(true);
    setTimeout(() => {
      setCopyId(false);
    }, 2000);
  };
  const handleOpenDatasources = () => {
    window.open(eventData?.status_msg?.post_link, "_blank");
  };
  const handleImageError = (event) => {
    event.target.src = ImagePlaceholder;
    event.target.alt = "placeholder-image";
  };

  const eventData = selectedEventDetails?.attributes;

  useEffect(() => {
    if (idPublishedShow) {
      getDetailsPopupPublish(idPublishedShow);
    }
  }, [idPublishedShow]);
  let checkDataSourceName =
    eventData?.data_source_name === "FACEBOOK" ||
    eventData?.data_source_name === "TWITTER" ||
    eventData?.data_source_name === "INSTAGRAM";

  return (
    <>
      <Box className="popup-details-container">
        {selectedEventDetails?.attributes ? (
          <>
            <Box className="publish-pop-up-header">
              <Typography component={"p"} className="publish-new-post-title">
                {CheckValueLocale(eventData?.status + "_post", "", {}, intl)}
              </Typography>
              <Box onClick={handleClose} className="publish-close-icon">
                <CloseIcon />
              </Box>
            </Box>
            <Divider className="publish-new-post-divider" />

            <Box className="publish-body">
              <Box className="action-btns">
                <Box>
                  {eventData?.status_msg?.post_link?.length ? (
                    <Button onClick={handleOpenDatasources}>
                      {eventData?.data_source_name?.toLowerCase() ==
                      "INSTAGRAM" ? (
                        <img src={IGPublishIcon} />
                      ) : eventData?.data_source_name == "TWITTER" ? (
                        <img src={xPlatform} alt="x-platform-logo" />
                      ) : (
                        <FontAwesomeIcon
                          className={
                            "data-sources-icon" +
                            "-" +
                            eventData?.data_source_name?.toLowerCase()
                          }
                          icon={handleIconDataSources(
                            eventData?.data_source_name?.toLowerCase(),
                          )}
                        />
                      )}
                      {CheckValueLocale("view_on_datasources", "", {}, intl)}
                      {CheckValueLocale(
                        eventData?.data_source_name?.toLowerCase(),
                        "",
                        {},
                        intl,
                      )}
                    </Button>
                  ) : null}
                  {haveAccountAccess?.length &&
                  ((selectedEventDetails?.attributes?.status === "scheduled" &&
                    checkDataSourceName) ||
                    (selectedEventDetails?.attributes?.status === "published" &&
                      selectedEventDetails?.attributes?.data_source_name ===
                        "FACEBOOK")) ? (
                    <Button onClick={() => setPublishTypeEvent("published")}>
                      <FontAwesomeIcon icon={faPen} />
                      {CheckValueLocale("published_edit", "", {}, intl)}
                    </Button>
                  ) : null}
                  <Button
                    className={
                      copyId ? "snakbar-copy btn-copy-id" : "btn-copy-id"
                    }
                    onClick={handleCopyId}
                  >
                    <FontAwesomeIcon icon={faLink} />
                    {CheckValueLocale("copy_post_id", "", {}, intl)}
                    {copyId ? (
                      <span className="snakbar-box">
                        {" "}
                        {CheckValueLocale("id_copied", "", {}, intl)}
                      </span>
                    ) : null}
                  </Button>
                </Box>
                {haveAccountAccess?.length &&
                (((selectedEventDetails?.attributes?.status === "scheduled" ||
                  selectedEventDetails?.attributes?.status === "failed") &&
                  checkDataSourceName) ||
                  (selectedEventDetails?.attributes?.status === "published" &&
                    eventData?.data_source_name === "FACEBOOK") ||
                  eventData?.data_source_name === "TWITTER") ? (
                  <>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      className={open ? "del-btn-menu open" : "del-btn-menu"}
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="del-from-calender-munu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "210px",
                        },
                      }}
                    >
                      <MenuItem onClick={handleCloseMenu}>
                        <Box
                          id="del-from-calender"
                          onClick={() =>
                            setDelPopup({
                              open: true,
                              loading: false,
                            })
                          }
                        >
                          <DeleteIcon />
                          {CheckValueLocale(
                            "del_from_calend",
                            "",
                            {
                              datasource: CheckValueLocale(
                                eventData?.data_source_name?.toLowerCase(),
                                "",
                                {},
                                intl,
                              ),
                            },
                            intl,
                          )}
                        </Box>
                      </MenuItem>
                    </Menu>
                  </>
                ) : null}
              </Box>
              <Divider className="publish-new-post-divider" />
              <Box className="content-box">
                <Typography className="content-title">
                  {CheckValueLocale("engagement_publish_content", "", {}, intl)}
                </Typography>
                <Box>
                  <Typography className="description">
                    {eventData?.text}
                  </Typography>
                  <Typography className="images-box">
                    <Box className="attachments-img-box">
                      {eventData?.attachments?.map((item) => {
                        return (
                          <Box className="attachments-img">
                            <img
                              src={item?.media_url}
                              onError={handleImageError}
                              alt=""
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Typography>
                  <Divider className="publish-new-post-divider" />
                  <Box className="item-publish-detils">
                    <Box className="publish-detils-start">
                      <Typography
                        component={"span"}
                        className="secondary-title"
                      >
                        {CheckValueLocale(
                          "engagement_publish_status",
                          "",
                          {},
                          intl,
                        )}
                      </Typography>
                      <Typography
                        className={
                          eventData?.status
                            ? "status-box " + eventData?.status
                            : "status-box"
                        }
                      >
                        <FontAwesomeIcon
                          className="icon-status"
                          icon={handleCheckstatus(eventData?.status)}
                        />
                        {eventData?.status}
                      </Typography>
                    </Box>
                    <Box className="publish-detils-end">
                      <Typography
                        component={"span"}
                        className="secondary-title"
                      >
                        {CheckValueLocale("publish_time", "", {}, intl)}
                      </Typography>
                      <Typography>
                        <FontAwesomeIcon
                          className="icon-time"
                          icon={faCalendar}
                        />
                        {moment(
                          eventData?.post_scheduled_times[0]?.scheduled_time,
                        )?.format(" h:mm a, DD MMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="item-publish-detils">
                    <Box className="publish-detils-start">
                      <Typography
                        component={"span"}
                        className="secondary-title"
                      >
                        {CheckValueLocale(
                          "engagement_publish_accounts",
                          "",
                          {},
                          intl,
                        )}
                      </Typography>
                      <Box className="user-box">
                        <Box className="img-accounts-publish">
                          <Typography
                            component={"span"}
                            className="main-user-img"
                          >
                            <img
                              src={
                                accountsPublishSelected[0]?.imageUrl
                                  ? accountsPublishSelected[0]?.imageUrl
                                  : accountsPublishSelected[0]?.imageUrl
                                      ?.charAt(1)
                                      ?.toUpperCase()
                              }
                              onError={handleImageError}
                              alt=""
                            />
                          </Typography>
                          <img
                            src={handleDatasourcesIcon(
                              accountsPublishSelected[0]?.data_source?.toLowerCase(),
                            )}
                          ></img>
                        </Box>
                        <Typography className="text-accounts-publish">
                          {accountsPublishSelected[0]?.name}
                        </Typography>
                        {eventData?.publisher_username?.length ? (
                          <FontAwesomeIcon
                            onClick={() =>
                              window.open(
                                getSocialLink(
                                  accountsPublishSelected[0]?.data_source?.toLowerCase(),
                                  eventData?.publisher_username,
                                ),
                                "_blank",
                              )
                            }
                            icon={faExternalLink}
                            className="external-link-accounts-publish"
                          />
                        ) : null}
                      </Box>
                    </Box>
                    <Box className="publish-detils-end">
                      <Typography
                        component={"span"}
                        className="secondary-title"
                      >
                        {CheckValueLocale("created_time", "", {}, intl)}
                      </Typography>
                      <Typography>
                        <FontAwesomeIcon
                          className="icon-time"
                          icon={faCalendar}
                        />
                        {moment(eventData?.created_at)?.format(
                          " h:mm a, DD MMM YYYY",
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="item-publish-detils">
                    <Box className="publish-detils-start">
                      <Typography
                        component={"span"}
                        className="secondary-title"
                      >
                        {CheckValueLocale(
                          "engagement_publish_user",
                          "",
                          {},
                          intl,
                        )}
                      </Typography>
                      <Typography className="user-details">
                        <Typography
                          component={"span"}
                          className="user-img-char"
                        >
                          {eventData?.user?.charAt(0)?.toUpperCase() +
                            eventData?.user?.charAt(1)?.toUpperCase()}
                        </Typography>
                        {eventData?.user}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="popup-details-loading">
            {CheckValueLocale("loading", "", {}, intl)}...
          </Box>
        )}
        <PopupModal
          body={delBody()}
          open={delPopup?.open}
          fixPadding={true}
          close={() =>
            !delPopup?.loading &&
            setDelPopup({
              open: false,
              loading: false,
            })
          }
        />
        <SnackBar
          open={snackBar?.open}
          severity={snackBar?.severity}
          message={CheckValueLocale(snackBar?.message, "", {}, intl)}
          title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        />
      </Box>
    </>
  );
};

export default DetailsPopup;
