import { useEffect, useState, useRef, useMemo } from "react";
import VideoFrame from "react-video-thumbnail";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  TextField,
  Breadcrumbs,
  Link,
  Box,
  MenuItem,
  Select,
  Avatar,
  Divider,
  Popover,
  Tooltip,
  Button,
  Skeleton,
  CircularProgress,
  Menu,
  Typography,
} from "@mui/material";
import "./reply.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTrash,
  faPlay,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle as faCheckCircleRegular,
  faEllipsis,
} from "@fortawesome/pro-regular-svg-icons";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import listenForOutsideClicks, {
  CheckValueLocale,
  getNumberOfChar,
  getDatasourceIconName,
  isEmptyObj,
  handlelUserRoles,
  classNames,
  getResetPhoneNumber,
} from "utils/helpers";
import MonitorsController from "services/controllers/monitorsController";
import CancelIcon from "@mui/icons-material/Cancel";
import DefaultAccountImg from "../../../Monitors/Components/replyModal/defaultAccountImg";
import SnackBar from "components/snackBar.js";
import Services from "services/api/Services.js";
import {
  faGrinAlt,
  faCommentAltLines,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCommentAltLines as faCommentAltLinesSolid,
  faPaperPlaneTop,
} from "@fortawesome/pro-solid-svg-icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import gif from "images/engagements-images/gif.svg";
import video from "images/engagements-images/video.svg";
import image from "images/engagements-images/image.svg";
import translateButton from "images/engagements-images/translateButton.svg";
import translate from "images/engagements-images/translate.svg";
import gifSolid from "images/engagements-images/gif-solid.svg";
import activeTranselation from "images/engagements-images/transelate-active.svg";
import update from "images/engagements-images/update.svg";
import EngagementTranslation from "../details/engagementTranslation/engagementTranslation";
import EngagementsController from "services/controllers/engagementsController";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import DOMPurify from "dompurify";
import twitterText from "twitter-text";
import LucButton from "shared/lucButton/lucButton";
import AssignHumanAiAgentModal from "./assignHumanAiAgentModal";
import TeamUnavailableAiAgentModal from "./teamUnavailableAiAgentModal";
import { useAutoAssignAiAgent } from "../aiAgents/aiAgentsHooks/useAutoAssignAiAgent";
import { useSelector } from "react-redux";
import { LockedEngagement } from "./components";
import { useDispatch } from "react-redux";
import { slaData } from "utils/redux/features/Engagements/EngagementsSlice";
import { TwitterReplyTo } from "./twitterReplyTo/twitterReplyTo";
import _ from "lodash";
import { AIAgentResponseTime } from "./components/aiAgentResponseTime";

// stg env

const getDefaultMaxCharForDMDatasources = (selectedCard = {}) => {
  const { isDm, data_source } = selectedCard;
  if (!isDm) {
    return 280;
  }
  switch (data_source) {
    case "TWITTER":
      return 10000;
    case "FACEBOOK":
      return 2000;
    case "INSTAGRAM":
      return 1000;
    case "WHATSAPP":
      return 4096;
    default:
      return 280;
  }
};

const IconReplyEllipsis = ({
  attachments,
  handleAttachmentsUpload,
  disabledStyleFirst,
  disabledCondition,
  mediaLength,
  inputRefGif,
  inputRefVideo,
  inputRefImg,
  handleClickEmoji,
  aiAgentId,
  assignee,
  userSignedInInteract,
  dataSource,
  replyType,
}) => {
  const intl = useIntl();
  const authorizedToInteract = +userSignedInInteract == +assignee?.userId;
  let notShownGifIcon =
    dataSource === "WHATSAPP" ||
    (dataSource?.toLowerCase() == "twitter" && replyType === "dm");
  return (
    <>
      {notShownGifIcon ? null : (
        <Tooltip
          title={
            !aiAgentId
              ? attachments?.length === 4
                ? CheckValueLocale("max_media_msg", "", {}, intl)
                : CheckValueLocale("gif_tooltip", "", {}, intl)
              : null
          }
          placement="top"
          arrow
          disableHoverListener={!authorizedToInteract}
        >
          <label
            htmlFor="gif-input"
            className={`reply-footer-icon ${
              disabledStyleFirst || aiAgentId || !authorizedToInteract
                ? "reply-footer-icon-disabled"
                : ""
            }`}
          >
            <img src={gif} />
            <input
              id="gif-input"
              type="file"
              accept=".gif, image/gif"
              onChange={handleAttachmentsUpload}
              className="modal-input-style"
              disabled={
                disabledCondition ||
                mediaLength ||
                aiAgentId ||
                !authorizedToInteract
              }
              ref={inputRefGif}
            />
          </label>
        </Tooltip>
      )}
      <Tooltip
        title={
          !aiAgentId
            ? attachments?.length === 4
              ? CheckValueLocale("max_media_msg", "", {}, intl)
              : CheckValueLocale("video", "", {}, intl)
            : null
        }
        placement="top"
        arrow
        disableHoverListener={!authorizedToInteract}
      >
        <label
          htmlFor="video-input"
          className={`reply-footer-icon ${
            disabledStyleFirst || aiAgentId || !authorizedToInteract
              ? "reply-footer-icon-disabled"
              : ""
          }`}
        >
          <img src={video} />
          <input
            id="video-input"
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={handleAttachmentsUpload}
            className="modal-input-style"
            disabled={
              disabledCondition ||
              mediaLength ||
              aiAgentId ||
              !authorizedToInteract
            }
            ref={inputRefVideo}
          />
        </label>
      </Tooltip>
      <Tooltip
        title={
          !aiAgentId
            ? attachments?.length === 4
              ? CheckValueLocale("max_media_msg", "", {}, intl)
              : CheckValueLocale("image", "", {}, intl)
            : null
        }
        placement="top"
        arrow
        disableHoverListener={!authorizedToInteract}
      >
        <label
          htmlFor="image-input"
          className={`reply-footer-icon ${
            disabledStyleFirst || aiAgentId || !authorizedToInteract
              ? "reply-footer-icon-disabled"
              : ""
          }`}
        >
          <img src={image} />
          <input
            id="image-input"
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            onChange={handleAttachmentsUpload}
            className="modal-input-style"
            disabled={
              disabledCondition ||
              mediaLength ||
              aiAgentId ||
              !authorizedToInteract
            }
            ref={inputRefImg}
          />
        </label>
      </Tooltip>
    </>
  );
};

const Reply = (props) => {
  const intl = useIntl();
  const inputRefGif = useRef(null);
  const inputRefVideo = useRef(null);
  const inputRefImg = useRef(null);
  const replyInputRef = useRef(null);
  const {
    replyData,
    showSuccessModal,
    selectedCard,
    setIsDeletedResponse,
    replyType,
    setLimitRateDate,
    limitRateDate,
    showSnackBar,
    setShowSnackBar,
    snackBarSeverity,
    setSnackbarSeverity,
    snackBarMessage,
    setSnackbarMessage,
    snackBarTitle,
    setSnackbarTitle,
    selectedFile,
    setSelectedFile,
    base64Code,
    setBase64Code,
    attachments,
    setAttachments,
    text,
    setText,
    mediaFile,
    setMediaFile,
    mediaLoader,
    setMediaLoader,
    btnLoading,
    setBtnLoading,
    selectedValue,
    setSelectedValue,
    loading,
    setLoading,
    postData = {},
    setPostData = () => {},
    setNewMsg = () => {},
    setIsReplied = () => {},
    translationActive,
    setTranslationActive,
    translationSource,
    setTranslationSource,
    translationTarget,
    setTranslationTarget,
    stats,
    setStats,
    languagesArraySource,
    languagesArrayTarget,
    translateStatus,
    setTranslateStatus,
    setOpenDetailsSideBar,
    setOpenDetailsSideBarReplay,
    setOpenSlaSideBar,
    setCheckAiSuggestions,
    setOpenSidebar,
    headerData,
    chatData = [],
    setOriginalReply = () => {},
    setDeletedMesssagesIds,
    openSidebar,
    openDetailsSideBarReplay,
    isSidebarsOpened,
    showAllIcon,
    setSelectedCardIteractionID,
    aiAgentList,
    setRefresh,
    setOpenSnackBarAiAgent,
    setSnackBarDataAiAgent,
    setInjectedMessage,
    aiAgentId,
    replyBoxInputTextRef,
    reduxSlaKeys,
    itemSelected,
    socket,
  } = props;
  const { delayed } = selectedCard;

  const repliedDate = replyData?.system_replies?.created_at;
  const replyBy = replyData?.system_replies?.replier_info?.email;

  const [maxChar, setMaxChar] = useState(
    getDefaultMaxCharForDMDatasources(selectedCard),
  );
  const [textLength, setTextLength] = useState(0);
  const [haveAccounts, setHaveAccounts] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [iconToDisplay, setIconToDisplay] = useState(null);
  const [translateReplyStatus, setTranslateReplyStatus] =
    useState("translateReply");
  const [translationText, setTranslationText] = useState("");
  const [translationSourceText, setTranslationSourceText] = useState("");
  const [translationSourceTextLoading, setTranslationSourceTextLoading] =
    useState(false);
  const [translationTextLoading, setTranslationTextLoading] = useState(false);
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [textareaState, setTextareaState] = useState(null);
  const dispatch = useDispatch();
  const [viewMoreIconsEl, setViewMoreIconsEl] = useState(null);
  const isViewMoreIconsOpen = Boolean(viewMoreIconsEl);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const [mediaErr, setMediaErr] = useState("");
  const [anchorElTranslation, setAnchorElTranslation] = useState(null);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [tempUserName, setTempUserName] = useState("");
  const [engamentAssignedSnackBar, setEngamentAssignedSnackBar] = useState({
    openSnackBar: false,
    severity: null,
    title: "",
    message: "",
  });
  const [openPopupAssignHuman, setOpenPopupAssignHuman] = useState(false);
  const [openPopupTeamUnavailable, setOpenPopupTeamUnavailable] =
    useState(false);
  const [aiAgentDetails, setAiAgentDetails] = useState({});
  const [autoReplyMessage, setAutoReplyMessage] = useState(null);
  // replay to twitter
  const [replyToSelectedUsers, setReplyToSelectedUsers] = useState([]);
  const [replyToUsers, setReplyToUsers] = useState([]);
  const [isTextInputFocused, setIsTextInputFocused] = useState(false);
  const [pendingReply, setPendingReply] = useState(false); // Reply is pending
  const [isOnline, setIsOnline] = useState(true); // Network status
  const [connectionSnack, setConnectionSnack] = useState(false);

  const [accountsList, setAccountsList] = useState([]);
  const [haveActiveAcc, setHaveActiveAcc] = useState(0);
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const userSignedIn = localStorage?.getItem("user_id");
  const assignedUser = reduxSlaData?.[reduxSlaKeys]?.userId;
  const authorizedToReply = +userSignedIn == +assignedUser;
  const selectedValueRef = useRef(selectedValue);
  const handleCloseSnack = () => {
    setShowSnackBar(false);
  };

  const { mutate: autoAssignAiAgent, isPending: autoAssignAiAgentLoading } =
    useAutoAssignAiAgent();
  const handleAppendText = (text) => {
    const currentPosition = replyInputRef?.current?.selectionStart || 0;
    const textLength = text?.length || 0;

    setText((prev) => {
      return (
        prev.substring(0, currentPosition) +
        text +
        prev.substring(currentPosition)
      );
    });
    const currentCaretPosition = currentPosition + textLength;
    replyInputRef?.current?.setSelectionRange(
      currentCaretPosition,
      currentCaretPosition,
    );
  };
  const updateReduxAfterAssign = (reduxData) => {
    EngagementsController.getUsers(
      window.localStorage.getItem("engagementsProductId"),
    ).then((usersRes) => {
      if (!usersRes?.errorMsg) {
        let slaDataCopy = { ...reduxData };
        slaDataCopy.users =
          usersRes?.data?.data > 0
            ? usersRes?.data?.data
            : [...reduxSlaData?.users];
        dispatch(slaData(slaDataCopy));
      }
    });
  };
  const handleClick = (event) => {
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
    setOpenSlaSideBar(false);
    setOpenDetailsSideBarReplay(true);
    setOpenSidebar(false);
    //setAnchorEl(event.currentTarget);
  };
  const handleViewMoreIconsClick = (event) => {
    setViewMoreIconsEl(event.currentTarget);
  };
  const handleViewMoreIconsClose = () => {
    setViewMoreIconsEl(null);
  };

  const handleTwitterErrorCases = (errMsg) => {
    switch (errMsg) {
      case "The Tweet you are replying to does not exist.":
        return "the_tweet_you_are_replying_to_does_not_exist";
      case "You cannot reply because the user has blocked you.":
        return "you_cannot_reply_because_the_user_has_blocked_you";
      case "You have exceeded the rate limit. Please retry after some time.":
        return "you_have_exceeded_the_rate_limit_please_retry_after_some_time";
      case "Temporary server issue. Retry the request.":
        return "temporary_server_issue_retry_the_request";
      case "Service Unavailable":
        return "service_unavailable";
      case "You attempted to reply to a Tweet that is deleted or not visible to you.":
        return "the_tweet_you_are_replying_to_does_not_exist_or_the_user_has_blocked_you";
      case "You are not allowed to create a Tweet with duplicate content.":
        return "you_are_not_allowed_to_create_a_tweet_with_duplicate_content";
      case "You are not permitted to create a non Community Tweet in reply to a Community Tweet.":
        return "you_are_not_permitted_to_create_a_non_community_tweet_in_reply";
      case "This operation is not permitted.":
        return "this_operation_is_not_permitted";
      case "You are not allowed to reply to this Tweet as the Tweet author has restricted who can reply to this Tweet.":
        return "not_allowed_to_reply_to_tweet_the_author_has_restricted_who_can";
      default:
        return false;
    }
  };

  useEffect(() => {
    if (aiAgentId && aiAgentList?.length) {
      setAiAgentDetails(
        aiAgentList?.find((item) => +item?.id == +aiAgentId) || {},
      );
    }
  }, [aiAgentList, aiAgentId]);

  useEffect(() => {
    let realText = translateStatus ? translationText?.trim() : text?.trim();
    setTextLength(realText?.length || 0);
    if (selectedCard.data_source === "TWITTER" && realText?.length) {
      const parsedTweet = twitterText.parseTweet(realText);
      setTextLength(parsedTweet?.weightedLength || 0);
    }
  }, [text, translateStatus, translationText]);

  const handleChangeText = (event) => {
    setText(event.target.value);
    if (replyType === "dm") {
      setOriginalReply(event.target.value);
    }
  };

  // handle accounts
  const handleChangeAccount = (event) => {
    setSelectedValue({ ...event.target.value });
    setReplyModalData({
      ...replyModalData,
      selectedValue: event.target.value?.displayName,
    });
  };

  const checkIdAccountDm = (dataAccounts, sourceName, accountName) => {
    let filteredAccountData = [];
    if (sourceName == "FACEBOOK") {
      filteredAccountData = dataAccounts?.filter(
        (obj) =>
          obj?.page_id == selectedCard?.agent_id && obj?.name == accountName,
      );
    } else if (sourceName == "WHATSAPP") {
      filteredAccountData = dataAccounts?.filter(
        (obj) =>
          getResetPhoneNumber(obj?.phone_number) ==
            getResetPhoneNumber(selectedCard?.agent_id) &&
          getResetPhoneNumber(obj?.phone_number) ==
            getResetPhoneNumber(accountName),
      );
    } else {
      filteredAccountData = dataAccounts?.filter(
        (obj) =>
          obj?.instagram_id == selectedCard?.agent_id &&
          obj?.user_name == accountName,
      );
    }
    return filteredAccountData?.length > 0;
  };

  useEffect(() => {
    setLoading(true);
    setSuggestionLoading(false);
    setCheckAiSuggestions(false);
    let dataSource =
      selectedCard?.data_source === "WHATSAPP"
        ? "WHATSAPP"
        : selectedCard.data_source === "FACEBOOK"
          ? "FACEBOOK"
          : selectedCard.data_source === "INSTAGRAM"
            ? "INSTAGRAM"
            : "TWITTER";

    let filteredAccounts = [];
    if (
      !showSuccessModal &&
      (replyData?.system_replies?.is_replied === undefined ||
        replyData?.system_replies?.is_replied === 0)
    ) {
      MonitorsController.getAllawedAccounts(
        window.localStorage.engagements_id,
        "reply",
      ).then((AllawedData) => {
        let allawedAccountArr =
          AllawedData?.data?.data?.user_accounts?.[dataSource];
        MonitorsController.getAllAccounts(window?.localStorage?.engagements_id)
          .then((data) => {
            if (data?.data?.status === 200) {
              let allAcounts = data?.data;
              if (
                dataSource === "INSTAGRAM" ||
                dataSource === "FACEBOOK" ||
                dataSource === "WHATSAPP"
              ) {
                const isWhastapp = dataSource === "WHATSAPP";
                allAcounts?.data[dataSource]?.included?.map((accountData) => {
                  if (
                    (!selectedCard?.isDm &&
                      accountData?.attributes?.engagements_products?.includes(
                        headerData?.product,
                      )) ||
                    selectedCard?.isDm
                  ) {
                    if (allawedAccountArr?.includes(+accountData?.id)) {
                      if (accountData?.attributes?.status == "active") {
                        setHaveActiveAcc(1);
                      }

                      filteredAccounts.push({
                        ...accountData?.attributes,
                        displayName: isWhastapp
                          ? accountData?.attributes?.phone_number
                          : dataSource === "INSTAGRAM"
                            ? accountData?.attributes?.user_name
                            : accountData?.attributes?.name,
                        accountId: accountData?.attributes?.account_info_id,
                        accountPageId: isWhastapp
                          ? accountData?.attributes?.business_account_id
                          : replyType === "dm"
                            ? dataSource === "FACEBOOK"
                              ? accountData?.attributes?.page_id
                              : accountData?.attributes?.instagram_id
                            : accountData?.attributes?.page_id,
                        app_name: isWhastapp
                          ? allAcounts?.data?.[dataSource]?.data?.find?.(
                              (item) =>
                                +item?.id ==
                                +accountData?.attributes?.account_info_id,
                            )?.attributes?.name
                          : null,
                      });
                    }
                  }
                });
              } else {
                allAcounts?.data[dataSource]?.data?.map((accountData) => {
                  // commented for now, may be needed later
                  // if (
                  //   accountData?.attributes?.used_in_private_channel === 0 &&
                  //   accountData?.attributes?.used_in_public_channel === 0
                  // ) {
                  if (
                    (!selectedCard?.isDm &&
                      accountData?.attributes?.engagements_products?.includes(
                        headerData?.product,
                      )) ||
                    selectedCard?.isDm
                  ) {
                    if (allawedAccountArr?.includes(+accountData?.id)) {
                      if (accountData?.attributes?.status == "active") {
                        setHaveActiveAcc(1);
                      }
                      filteredAccounts.push({
                        ...accountData?.attributes,
                        displayName: accountData?.attributes?.user_name,
                        accountId: accountData?.attributes?.id,
                      });
                    }
                  }
                });
              }
              setAccountsList([...filteredAccounts]);

              if (filteredAccounts?.length) {
                filteredAccounts?.map((account, i) => {
                  if (
                    (replyType === "dm" &&
                      ((dataSource === "TWITTER" &&
                        "@" + selectedCard?.agent_username ===
                          account?.displayName) ||
                        checkIdAccountDm(
                          filteredAccounts,
                          dataSource,
                          account?.displayName,
                        ))) ||
                    replyType !== "dm"
                  ) {
                    setHaveAccounts(true);
                  }
                });
                // set default selected account
                if (replyType !== "dm") {
                  let queryData = {
                    product_id: window.localStorage.engagements_id,
                    data_source_name: dataSource,
                    monitor_id: selectedCard?.tracker_id,
                  };
                  EngagementsController.getLastUsedAccount(queryData).then(
                    (res) => {
                      if (!res?.errorMsg) {
                        let selectedAccountData = filteredAccounts?.filter(
                          (obj) => obj?.id == res?.data?.last_used_account,
                        );
                        selectedAccountData?.length &&
                          setSelectedValue({
                            ...selectedAccountData[0],
                          });
                        setReplyModalData({
                          ...replyModalData,
                          selectedValue: selectedAccountData[0]?.displayName,
                        });
                      }
                    },
                  );
                } else {
                  let selectedAccountData = [];

                  if (dataSource === "TWITTER") {
                    selectedAccountData = filteredAccounts?.filter(
                      (obj) =>
                        obj?.user_name == `@${selectedCard?.agent_username}`,
                    );
                  } else if (dataSource === "FACEBOOK") {
                    selectedAccountData = filteredAccounts?.filter(
                      (obj) => obj?.page_id == selectedCard?.agent_id,
                    );
                  } else if (dataSource === "WHATSAPP") {
                    selectedAccountData = filteredAccounts?.filter(
                      (obj) =>
                        getResetPhoneNumber(obj?.phone_number) ==
                        getResetPhoneNumber(selectedCard?.agent_id),
                    );
                  } else {
                    selectedAccountData = filteredAccounts?.filter(
                      (obj) => obj?.instagram_id == selectedCard?.agent_id,
                    );
                  }
                  selectedAccountData?.length &&
                    setSelectedValue({
                      ...selectedAccountData[0],
                    });
                  setReplyModalData({
                    ...replyModalData,
                    selectedValue: selectedAccountData[0]?.displayName,
                  });
                }
              }
              setLoading(false);
            } else {
              setLoading(false);
            }
            setSuggestionLoading(true);
          })
          .catch((error) => {
            setLoading(false);
            setSuggestionLoading(true);
          });
      });
    }
  }, [selectedCard]);

  //handle get data from socket at public engag
  useEffect(() => {
    if (!isEmptyObj(postData)) {
      if (replyType !== "dm") {
        socket.on("engagement_status", (data) => {
          if (data.status_code === 200 || data.status_code === 201) {
            if (data?.content?.item_id) {
              setSelectedCardIteractionID(data?.content?.item_id);
            }
            const repliesArr = postData?.replies?.length
              ? [
                  // ...postData?.replies,
                  {
                    created_at: data?.content?.created_at,
                    isSocket: true,
                    profile_image_url: selectedValue?.image_url,
                    name: selectedValue?.displayName,
                    text: data?.content?.text,
                    original_replay: translateStatus ? text : null,
                    is_account_owner: data?.content?.is_account_owner,
                    account_identifier: data?.content?.account_identifier,
                    item_id: data?.content?.item_id,
                    media: mediaFile.length
                      ? mediaFile?.map((url) => {
                          return { media_url: url };
                        })
                      : [],
                  },
                ]
              : [
                  {
                    created_at: data?.content?.created_at,
                    isSocket: true,
                    profile_image_url: selectedValue?.image_url,
                    name: selectedValue?.displayName,
                    text: data?.content?.text,
                    original_replay: translateStatus ? text : null,
                    is_account_owner: data?.content?.is_account_owner,
                    item_id: data?.content?.item_id,
                    account_identifier: data?.content?.account_identifier,
                    media: mediaFile.length
                      ? mediaFile?.map((url, index) => {
                          return {
                            media_url: url,
                            isVideo: !(
                              selectedFile[index]?.type === ".gif" ||
                              selectedFile[index]?.type === "image/jpeg" ||
                              selectedFile[index]?.type === "image/gif" ||
                              selectedFile[index]?.type?.includes("gif") ||
                              selectedFile[index]?.type?.includes("image") ||
                              selectedFile[index]?.type?.includes("photo")
                            ),
                          };
                        })
                      : [],
                  },
                ];
            setPostData({
              ...postData,
              replies: [...repliesArr],
            });
            setText("");
            setAttachments([]);
            setMediaFile([]);
            setSelectedFile([]);
            setBase64Code([]);
            setIsDeletedResponse(false);
            setIsReplied(true);
            setTranslateReplyStatus("translateReply");
            setTranslationText("");
          } else {
            if (data?.content?.error == "RATE_LIMIT_ERROR") {
              setSnackbarMessage("limit_rate_error");
              let date = moment
                .unix(+data?.content?.rate_limit_reset)
                .utc()
                .format("DD/MM/YYYY [at] hh:mm A");
              if (intl.locale == "ar") {
                let dateArr = date?.split(" ");
                date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
              }
              setLimitRateDate(date);
              setSnackbarTitle("");
            } else if (
              data?.content?.error == "INTERACTION_ALREADY_COMPLETED"
            ) {
              setSnackbarMessage("interaction_is_completed");
              setSnackbarTitle("");
            } else if (data?.content?.error?.detail == "ACTION_RESTRICTED") {
              setSnackbarMessage("action_restricted_reply_engagement_msg");
              setSnackbarTitle("");
            } else {
              // This condition will handle the error cases for twitter
              const errMsgText = handleTwitterErrorCases(data?.content?.error);
              if (errMsgText) {
                setSnackbarMessage(errMsgText);
                setSnackbarTitle("");
              } else {
                // This condition will handle the default error cases
                setSnackbarMessage("request_access_error_desc");
                setSnackbarTitle("request_access_error_heading");
              }
            }
            setSnackbarSeverity("error");
            setShowSnackBar(true);
          }
          setBtnLoading(false);
          setMediaLoader(false);
        });
      }
    }
  }, [
    socket,
    postData,
    selectedValue?.displayName,
    selectedFile,
    selectedValue?.image_url,
    mediaFile,
    translateStatus,
  ]);

  const getPublicData = (res) => {
    if (res?.status_code === 200 || res?.status_code === 201) {
      const content = res?.data || {};

      if (content?.item_id) {
        setSelectedCardIteractionID(content?.item_id);
      }
      const repliesArr = postData?.replies?.length
        ? [
            // ...postData?.replies,
            {
              created_at: content?.created_at,
              isSocket: true,
              profile_image_url: selectedValue?.image_url,
              name: selectedValue?.displayName,
              text: content?.text,
              original_replay: translateStatus ? text : null,
              is_account_owner: content?.is_account_owner,
              item_id: content?.item_id,
              media: mediaFile.length
                ? mediaFile?.map((url) => {
                    return { media_url: url };
                  })
                : [],
            },
          ]
        : [
            {
              created_at: content?.created_at,
              isSocket: true,
              profile_image_url: selectedValue?.image_url,
              name: selectedValue?.displayName,
              text: content?.text,
              original_replay: translateStatus ? text : null,
              is_account_owner: content?.is_account_owner,
              item_id: content?.item_id,
              media: mediaFile.length
                ? mediaFile?.map((url, index) => {
                    return {
                      media_url: url,
                      isVideo: !(
                        selectedFile[index]?.type === ".gif" ||
                        selectedFile[index]?.type === "image/jpeg" ||
                        selectedFile[index]?.type === "image/gif" ||
                        selectedFile[index]?.type?.includes("gif") ||
                        selectedFile[index]?.type?.includes("image") ||
                        selectedFile[index]?.type?.includes("photo")
                      ),
                    };
                  })
                : [],
            },
          ];
      setPostData({
        ...postData,
        replies: [...repliesArr],
      });
      setText("");
      setAttachments([]);
      setMediaFile([]);
      setSelectedFile([]);
      setBase64Code([]);
      setIsDeletedResponse(false);
      setIsReplied(true);
      setTranslateReplyStatus("translateReply");
      setTranslationText("");
    } else {
      res.content = res?.errorMsg?.response?.data;
      if (res?.content?.error == "RATE_LIMIT_ERROR") {
        setSnackbarMessage("limit_rate_error");
        let date = moment
          .unix(+res.content?.rate_limit_reset)
          .utc()
          .format("DD/MM/YYYY [at] hh:mm A");
        if (intl.locale == "ar") {
          let dateArr = date?.split(" ");
          date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
        }
        setLimitRateDate(date);
        setSnackbarTitle("");
      } else if (res?.content?.error == "INTERACTION_ALREADY_COMPLETED") {
        setSnackbarMessage("interaction_is_completed");
        setSnackbarTitle("");
      } else if (res?.content?.error?.detail == "ACTION_RESTRICTED") {
        setSnackbarMessage("action_restricted_reply_engagement_msg");
        setSnackbarTitle("");
      } else {
        // This condition will handle the error cases for twitter
        const errMsgText = handleTwitterErrorCases(res?.content?.error);
        if (errMsgText) {
          setSnackbarMessage(errMsgText);
          setSnackbarTitle("");
        } else {
          // This condition will handle the default error cases
          setSnackbarMessage("request_access_error_desc");
          setSnackbarTitle("request_access_error_heading");
        }
      }
      setSnackbarSeverity("error");
      setShowSnackBar(true);
    }
    setBtnLoading(false);
    setMediaLoader(false);
  };

  const handleUploadMedia = (queryData) => {
    setMediaLoader(true);
    EngagementsController.uploadMediaEngagement(queryData).then((res) => {
      const { data } = res;
      if (data?.status_code === 200 || data?.status_code === 201) {
        setAttachments([...attachments, data?.data]);
      } else {
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(
          replyType === "dm" ? "request_access_error_desc" : "please_try_again",
        );
        setSnackbarTitle(
          replyType === "dm"
            ? "request_access_error_heading"
            : "reply_try_again",
        );
      }
      setMediaLoader(false);
    });
    // uploadMediaEngagement
  };
  const sendReplyDataV4 = (queryData) => {
    const selectedValue = selectedValueRef.current;
    EngagementsController.sendReplyDataV4(queryData).then((res) => {
      if (replyType != "dm") {
        getPublicData(res);
        return;
      }

      if (res?.status_code === 201 || res?.status_code === 200) {
        const content = res?.data || {};
        // in case of whatsapp attachements we already have the attachments uploaded so we don't need to make it temporary
        const attachment = content?.attachments?.[0] || {};
        const isWhastapp = selectedCard?.data_source === "WHATSAPP";
        const media =
          isWhastapp && attachment?.media_id
            ? {
                media_url: attachment?.media_id,
              }
            : {};

        setNewMsg({
          ...content,
          is_temporary: true,
          content: {
            media: JSON.stringify(media),
            text: content?.text,
            type: attachment?.media_type || undefined,
            url_temporary:
              isWhastapp && attachment?.media_id
                ? false
                : !!content?.attachments?.length,
          },
          created_at: content?.created_at,
          direction:
            isWhastapp && content?.direction
              ? content?.direction || "outbound"
              : "outbound",
          message_id: content?.item_id,
          username: selectedValue?.displayName,
          name: tempUserName,
          system_replies: {
            replier_info: {
              email: content?.email,
              app_name: selectedValue?.app_name,
            },
          },
        });

        if (content?.item_id) {
          setSelectedCardIteractionID(content?.item_id);
        }
        // Instead of adding this code at the end of handleReply() function, we are adding it here
        setPendingReply(false);
        setText("");
        setAttachments([]);
        setMediaFile([]);
        setSelectedFile([]);
        setBase64Code([]);
        setTranslateReplyStatus("translateReply");
        setTranslationText("");
      } else {
        res.content = res?.errorMsg?.response?.data;
        if (
          res?.content?.error == "RATE_LIMIT_ERROR" ||
          (res?.errorMsg?.status === 403 &&
            res?.content?.error === "Rate limit exceeded")
        ) {
          setSnackbarMessage("limit_rate_error_dm");
          let date = moment
            .unix(+res?.content?.rate_limit_reset)
            .utc()
            .format("DD/MM/YYYY [at] hh:mm A");
          if (intl.locale == "ar") {
            let dateArr = date?.split(" ");
            date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
          }
          setLimitRateDate(date);
          setSnackbarTitle("");
        } else if (res?.content?.error == "INTERACTION_ALREADY_COMPLETED") {
          setSnackbarMessage("interaction_is_completed");
          setSnackbarTitle("");
        } else if (res?.content?.error?.detail == "ACTION_RESTRICTED") {
          setSnackbarMessage("action_restricted_reply_engagement_msg");
          setSnackbarTitle("");
        } else {
          const errMsgText = handleTwitterErrorCases(res?.content?.error);
          if (errMsgText) {
            setSnackbarMessage(errMsgText);
            setSnackbarTitle("");
          } else {
            setSnackbarMessage("request_access_error_desc");
            setSnackbarTitle("request_access_error_heading");
          }
        }
        setSnackbarSeverity("error");
        setShowSnackBar(true);
        setBtnLoading(false);
      }
      setMediaLoader(false);
    });
  };

  const sendReplyDataV5 = (queryData) => {
    const selectedValue = selectedValueRef.current;

    EngagementsController.sendReplyDataV5(queryData).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        const content = res?.data || {};
        // in case of whatsapp attachements we already have the attachments uploaded so we don't need to make it temporary
        const attachment = content?.attachments?.[0] || {};
        const isWhastapp = selectedCard?.data_source === "WHATSAPP";
        const media =
          isWhastapp && attachment?.media_id
            ? {
                media_url: attachment?.media_id,
              }
            : {};

        setNewMsg({
          ...content,
          is_temporary: true,
          content: {
            media: JSON.stringify(media),
            text: content?.text,
            type: attachment?.media_type || undefined,
            url_temporary:
              isWhastapp && attachment?.media_id
                ? false
                : !!content?.attachments?.length,
          },
          created_at: content?.created_at,
          direction:
            isWhastapp && content?.direction
              ? content?.direction || "outbound"
              : "outbound",
          message_id: content?.item_id,
          username: selectedValue?.displayName,
          name: tempUserName,
          system_replies: {
            replier_info: {
              email: content?.email,
              app_name: selectedValue?.app_name,
            },
          },
        });

        if (content?.item_id) {
          setSelectedCardIteractionID(content?.item_id);
        }
        // Instead of adding this code at the end of handleReply() function, we are adding it here
        setPendingReply(false);
        setText("");
        setAttachments([]);
        setMediaFile([]);
        setSelectedFile([]);
        setBase64Code([]);
        setTranslateReplyStatus("translateReply");
        setTranslationText("");
      } else {
        res.content = res?.errorMsg?.response?.data;
        if (
          res?.content?.error == "RATE_LIMIT_ERROR" ||
          (res?.errorMsg?.status === 403 &&
            res?.content?.error === "Rate limit exceeded")
        ) {
          setSnackbarMessage("limit_rate_error_dm");
          let date = moment
            .unix(+res?.content?.rate_limit_reset)
            .utc()
            .format("DD/MM/YYYY [at] hh:mm A");
          if (intl.locale == "ar") {
            let dateArr = date?.split(" ");
            date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
          }
          setLimitRateDate(date);
          setSnackbarTitle("");
        } else if (res?.content?.error == "INTERACTION_ALREADY_COMPLETED") {
          setSnackbarMessage("interaction_is_completed");
          setSnackbarTitle("");
        } else if (res?.content?.error?.detail == "ACTION_RESTRICTED") {
          setSnackbarMessage("action_restricted_reply_engagement_msg");
          setSnackbarTitle("");
        } else {
          const errMsgText = handleTwitterErrorCases(res?.content?.error);
          if (errMsgText) {
            setSnackbarMessage(errMsgText);
            setSnackbarTitle("");
          } else {
            setSnackbarMessage("request_access_error_desc");
            setSnackbarTitle("request_access_error_heading");
          }
        }
        setSnackbarSeverity("error");
        setShowSnackBar(true);
        setBtnLoading(false);
      }
      setMediaLoader(false);
    });
  };

  //handle get data from socket at DM engag
  useEffect(() => {
    if (replyType === "dm") {
      //setLoading(true);
      socket.on("engagement_status", (data) => {
        const selectedValue = selectedValueRef.current;
        if (data.status_code === 200 || data.status_code === 201) {
          const content = data?.content || {};
          // in case of whatsapp attachements we already have the attachments uploaded so we don't need to make it temporary
          const attachment = content?.attachments?.[0] || {};
          const isWhastapp = selectedCard?.data_source === "WHATSAPP";
          const media =
            isWhastapp && attachment?.media_id
              ? {
                  media_url: attachment?.media_id,
                }
              : {};

          setNewMsg({
            ...content,
            is_temporary: true,
            content: {
              media: JSON.stringify(media),
              text: content?.text,
              type: attachment?.media_type || undefined,
              url_temporary:
                isWhastapp && attachment?.media_id
                  ? false
                  : !!content?.attachments?.length,
            },
            created_at: content?.created_at,
            direction:
              isWhastapp && content?.direction
                ? content?.direction || "outbound"
                : "outbound",
            message_id: content?.item_id,
            username: selectedValue?.displayName,
            name: tempUserName,
            system_replies: {
              replier_info: {
                email: content?.email,
                app_name: selectedValue?.app_name,
              },
            },
          });

          if (data?.content?.item_id) {
            setSelectedCardIteractionID(data?.content?.item_id);
          }
          // Instead of adding this code at the end of handleReply() function, we are adding it here
          setPendingReply(false);
          setText("");
          setAttachments([]);
          setMediaFile([]);
          setSelectedFile([]);
          setBase64Code([]);
          setTranslateReplyStatus("translateReply");
          setTranslationText("");
        } else {
          if (
            data?.content?.error == "RATE_LIMIT_ERROR" ||
            (data?.status_code === 403 &&
              data?.content?.error === "Rate limit exceeded")
          ) {
            setSnackbarMessage("limit_rate_error_dm");
            let date = moment
              .unix(+data?.content?.rate_limit_reset)
              .utc()
              .format("DD/MM/YYYY [at] hh:mm A");
            if (intl.locale == "ar") {
              let dateArr = date?.split(" ");
              date = `${dateArr[0]} الساعة ${dateArr[2]} ${dateArr[3] == "PM" ? "م" : "ص"}`;
            }
            setLimitRateDate(date);
            setSnackbarTitle("");
          } else if (data?.content?.error == "INTERACTION_ALREADY_COMPLETED") {
            setSnackbarMessage("interaction_is_completed");
            setSnackbarTitle("");
          } else if (data?.content?.error?.detail == "ACTION_RESTRICTED") {
            setSnackbarMessage("action_restricted_reply_engagement_msg");
            setSnackbarTitle("");
          } else {
            const errMsgText = handleTwitterErrorCases(data?.content?.error);
            if (errMsgText) {
              setSnackbarMessage(errMsgText);
              setSnackbarTitle("");
            } else {
              setSnackbarMessage("request_access_error_desc");
              setSnackbarTitle("request_access_error_heading");
            }
          }
          setSnackbarSeverity("error");
          setShowSnackBar(true);
          setBtnLoading(false);
        }
        setMediaLoader(false);
      });
      //on remove message from the conversation
      socket.on("delete_live_data", (data) => {
        const deletedMessageId = data?.single_interaction?.message_id;
        setDeletedMesssagesIds((prev) => [...prev, deletedMessageId]);
      });
    }
  }, [socket, tempUserName, intl]);

  useEffect(() => {
    if (replyType === "dm") {
      socket.on("live_data", (data) => {
        setNewMsg({
          ...data?.single_interaction?.conversation[0],
        });
      });
    }
  }, [socket]);

  // socket media
  const handleAttachmentsUpload = async (event) => {
    const file = event.target?.files[0];
    if (file) {
      if (Math.round(file?.size / (1024 * 1024)) > 10) {
        setMediaErr("size_media_err");
      } else if (
        event.target.id === "gif-input" &&
        file?.type !== "image/gif"
      ) {
        setMediaErr("gif_media_err");
      } else if (
        event.target.id === "video-input" &&
        file?.type !== "video/mp4"
      ) {
        setMediaErr("vid_media_err");
      } else if (
        event.target.id === "image-input" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpeg"
      ) {
        setMediaErr("img_media_err");
      } else {
        setMediaErr("");
        const reader = new FileReader();
        reader.onload = () => {
          setBase64Code([...base64Code, reader.result]);
        };
        reader.readAsDataURL(file);
        setSelectedFile([...selectedFile, file]);
        setMediaFile([...mediaFile, URL.createObjectURL(file)]);
      }

      let whatsappParams = {};
      if (selectedCard?.data_source === "WHATSAPP") {
        whatsappParams = {
          file_type: file?.type?.split("/")[0],
          company_timezone: +window.localStorage.getItem("companyInfo"),
        };
      }
      try {
        if (!socket.connected) {
          const formDataFile = new FormData();
          formDataFile.append("upload_file", file);
          formDataFile.append("publisher_name", selectedValue?.displayName);
          formDataFile.append("account_info_id", selectedValue?.accountId);
          formDataFile.append("monitor_id", selectedCard?.tracker_id);
          formDataFile.append("data_source_name", selectedCard?.data_source);
          formDataFile.append(
            "product_id",
            localStorage.getItem("engagementsProductId"),
          );
          if (selectedCard?.data_source === "WHATSAPP") {
            formDataFile.append("file_type", whatsappParams.file_type);
            formDataFile.append(
              "company_timezone",
              whatsappParams.company_timezone,
            );
          }

          handleUploadMedia(formDataFile);
        } else {
          setMediaLoader(true);
          socket.emit("upload_media", {
            upload_file: file,
            publisher_name: selectedValue?.displayName,
            account_info_id: selectedValue?.accountId,
            monitor_id: selectedCard?.tracker_id,
            data_source_name: selectedCard?.data_source,
            product_id: window.localStorage.getItem("engagementsProductId"),
            media_type: file?.type,
            "luc-authorization": localStorage.getItem("user_token"),
            ...whatsappParams,
          });
        }
      } catch (error) {}
    }
    replyInputRef.current?.focus?.();
  };

  const handleRemoveAttachments = (index) => {
    const attachments = [...selectedFile];
    attachments.splice(index, 1);
    setSelectedFile(attachments);

    mediaFile.splice(index, 1);
    setMediaFile(mediaFile);

    const base64 = [...base64Code];
    base64.splice(index, 1);
    setBase64Code(base64);

    const attachment = [...attachments];
    attachment.splice(index, 1);
    setAttachments(attachment);

    if (inputRefGif?.current) {
      inputRefGif.current.value = null;
    }
    if (inputRefVideo?.current) {
      inputRefVideo.current.value = null;
    }
    if (inputRefImg?.current) {
      inputRefImg.current.value = null;
    }
    replyInputRef.current?.focus?.();
  };
  // socket check state of upload attachments
  useEffect(() => {
    socket.on("upload_status", (data) => {
      if (data.status_code === 200 || data.status_code === 201) {
        setAttachments([...attachments, data?.content]);
      } else {
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(
          replyType === "dm" ? "request_access_error_desc" : "please_try_again",
        );
        setSnackbarTitle(
          replyType === "dm"
            ? "request_access_error_heading"
            : "reply_try_again",
        );
      }
      setMediaLoader(false);
    });
  }, [socket]);

  useEffect(() => {
    if (replyType === "dm") {
      socket.emit("join_room", {
        company_id: window.localStorage.getItem("id"),
        tracker_id: selectedCard.tracker_id,
        conversation_id: selectedCard.conversation_id,
      });
    }
  }, [selectedCard]);
  useEffect(() => {
    if (replyType === "dm") {
      socket.on("join_room", (data) => {});
    }
  }, [selectedCard]);

  useEffect(() => {
    if (!openSidebar && suggestionLoading) {
      setCheckAiSuggestions(true);
    }
  }, [openSidebar, suggestionLoading]);

  // send data with socket
  const handleReply = () => {
    if (replyType !== "dm") {
      setMediaLoader(true);
    }
    setPendingReply(true); // Mark reply as pending
    setBtnLoading(true);
    // in public twitter we need to exclude the users that we didn't select in the replied to
    let publicTwitterParams = {};
    if (replyType !== "dm" && selectedCard?.data_source === "TWITTER") {
      const excludedUserIds = replyToUsers
        ?.filter(
          (user) =>
            !replyToSelectedUsers?.some(
              (selectedUser) => selectedUser?.id === user?.id,
            ),
        )
        ?.map((user) => user?.id);
      publicTwitterParams = {
        exclude_reply_user_ids: excludedUserIds,
      };
    }
    let publishEngagementObj = {
      "luc-authorization": localStorage.getItem("user_token"),
      account_info_id: selectedValue?.accountId,
      publisher_name: selectedValue?.displayName,
      data_source_name: selectedCard?.data_source,
      monitor_id: selectedCard?.tracker_id,
      product_id: window.localStorage.getItem("engagementsProductId"),
      text: translateStatus ? translationText?.trim() : text?.trim(),
      attachments: attachments,
      sla_id: selectedCard?.sla_info?.sla_id,
      routing_id: selectedCard?.sla_info?.routing_id,
      screen_name: tempUserName,
      ...publicTwitterParams,
    };

    let whatsappParams = {};
    if (selectedCard?.data_source === "WHATSAPP") {
      const item_type = attachments?.length
        ? attachments?.[0]?.media_type
        : "text";
      whatsappParams = {
        is_dm: true,
        source_number: selectedValue?.displayName,
        recipient_number: selectedCard?.client_id,
        app_name: selectedValue?.app_name,
        item_type,
        publisher_name: selectedValue?.displayName,
        client_name: selectedCard?.client_name,
      };
    }

    if (replyType === "dm") {
      publishEngagementObj = {
        ...publishEngagementObj,
        item_type: "message",
        parent_id: selectedCard?.conversation_id,
        recipient_id: selectedCard?.client_id,
        agent_id: selectedCard?.agent_id,
        ...whatsappParams,
      };
    } else {
      publishEngagementObj = {
        ...publishEngagementObj,
        parent_id: selectedCard?.tweet_id,
        item_type: selectedCard?.type === "comment" ? "reply" : "comment",
      };
    }
    if (!socket.connected) {
      if (selectedCard?.data_source === "WHATSAPP") {
        sendReplyDataV5(publishEngagementObj);
      } else {
        sendReplyDataV4(publishEngagementObj);
      }
    } else {
      socket.emit("publish_engagement", publishEngagementObj);
    }
  };
  // remove snackvbar no internet
  // useEffect(() => {
  //   const handleOnline = () => setIsOnline(true);
  //   const handleOffline = () => setIsOnline(false);

  //   window.addEventListener("online", handleOnline);
  //   window.addEventListener("offline", handleOffline);

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //     window.removeEventListener("offline", handleOffline);
  //   };
  // }, []);

  useEffect(() => {
    if (pendingReply) {
      let connectErrCount = 0;
      socket.on("connect_error", () => {
        ++connectErrCount;
        if (connectErrCount === 1) {
          setBtnLoading(false);
          setMediaLoader(false);
          setPendingReply(false);
          setConnectionSnack(true);
          setTimeout(() => setConnectionSnack(false), 5000);
        }
      });
    }
  }, [socket, pendingReply]);

  const handleTextInputFocus = () => {
    replyInputRef.current?.focus?.();
  };
  useEffect(() => {
    replyInputRef.current?.focus?.();
    if (replyBoxInputTextRef?.current) {
      replyBoxInputTextRef.current = {
        getCaretPosition: () => {
          return replyInputRef?.current?.selectionStart || 0;
        },
        focus: (nextPosition) => {
          setTimeout(() => {
            replyInputRef?.current?.focus();
            replyInputRef?.current?.setSelectionRange(
              nextPosition,
              nextPosition,
            );
          }, 100);
        },
      };
    }
  }, []);

  const successModal = () => (
    <Box className="display-in-row">
      <Box className="replied-success-modal replied-success-modal-ca">
        <FontAwesomeIcon icon={faCheckCircle} />
        <span>{CheckValueLocale("replied_success", "", {}, intl)},</span>
      </Box>
      <Box className="success-modal-description success-modal-description-ca">
        <span>{CheckValueLocale("by", "", {}, intl)}</span>
        <div>{replyBy ?? localStorage.getItem("email")}</div>
      </Box>
      <Box className="separator-style">-</Box>
      <Box className="success-modal-description success-modal-date">
        <span>{CheckValueLocale("reply_date", "", {}, intl)}</span>
        <div>
          {repliedDate
            ? moment?.unix(repliedDate).utc()?.format("DD/MM/YYYY [at] hh:mm A")
            : replyModalData?.replyTime}
        </div>
      </Box>
    </Box>
  );

  const errorModal = () => (
    <Box className="display-in-row">
      <Box className="replied-error-modal replied-error-modal-ca">
        <CancelIcon />
        <span>{CheckValueLocale("wrong_request_parameter", "", {}, intl)}</span>
      </Box>
      <Box className="error-modal-description error-modal-description-ca">
        <span>{CheckValueLocale("please", "", {}, intl)}</span>
        <div onClick={() => setShowErrorModal(false)}>
          {CheckValueLocale("reply_try_again", "", {}, intl)}
        </div>
      </Box>
    </Box>
  );

  useEffect(() => {
    setIconToDisplay(null);
    (async () => {
      const image = new Image();
      image.src = selectedValue?.image_url;
      image.onload = () => {
        setIconToDisplay(selectedValue?.image_url);
      };
    })();
  }, [selectedValue?.image_url]);

  useEffect(() => {
    if (translateReplyStatus === "replyTranslated" && translateStatus) {
      setTranslateReplyStatus("updateTranslation");
    }
  }, [text]);

  useEffect(() => {
    closeTranslationBox();
  }, [translationTarget, translationSource]);

  let textCheck =
    replyType === "dm"
      ? textLength === 0
      : !mediaFile?.length && textLength === 0;

  const replyBtnStyle =
    textCheck ||
    mediaLoader ||
    !selectedValue?.displayName ||
    textLength > maxChar ||
    btnLoading ||
    (translateReplyStatus !== "replyTranslated" && translateStatus);

  const showSuccessCondition =
    showSuccessModal || replyData?.system_replies?.is_replied === 1;

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const breadCrumbsStyle = textLength > maxChar ? "word-reached" : "";
  const emojyAnchorOrigin =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "left",
        }
      : {
          vertical: "top",
          horizontal: "right",
        };

  const emojyTransformOrigin =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "left",
        }
      : {
          vertical: "bottom",
          horizontal: "right",
        };
  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };
  const anchorPositionTranslation =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "left",
        }
      : {
          vertical: "top",
          horizontal: "right",
        };
  const transformPositionTranslation =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "left",
        }
      : {
          vertical: "bottom",
          horizontal: "right",
        };

  const attachmentsLength = replyType === "dm" ? 1 : 4;
  const mediaLength = replyType === "dm" ? base64Code?.length : mediaLoader;

  const disabledCondition =
    !selectedValue?.displayName || attachments?.length === attachmentsLength;

  const disabledStyleFirst = disabledCondition || mediaLength;

  const isTranslationSourceAutoDetect = translationSource === "auto_detect";

  // emoji Dropdown HANDLER
  const handleClickEmoji = () => {
    setAnchorElEmoji(replyInputRef.current);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
    // added setTimeout here because the setAnchorElEmoji will be called after the handleCloseEmoji finish
    // so if we called the focus directly it will not work the becase the emojy dropdown will still be exist
    setTimeout(() => {
      replyInputRef.current?.focus?.();
    }, 100);
  };
  const openEmoji = Boolean(anchorElEmoji);

  // add emoji to text after select and hide emoji Dropdown
  const emojiSelect = (e) => {
    textareaState === "translation-text"
      ? setTranslationText(translationText + e)
      : handleAppendText(e);
  };

  // translation HANDLER
  const handleClickTranslation = (event) => {
    setAnchorElTranslation(event.currentTarget);
  };
  const handleCloseTranslation = () => {
    setAnchorElTranslation(null);
  };
  const openTranslation = Boolean(anchorElTranslation);
  const translateHandler = (event) => {
    if (translationActive) {
      if (translateStatus) {
        closeTranslationBox();
      } else {
        setTranslateStatus(true);
        replyInputRef.current?.focus?.();
        if (isTranslationSourceAutoDetect) {
          getSourceLanguage();
        }
      }
    } else {
      handleClickTranslation(event);
    }
  };
  const closeTranslationBox = () => {
    setTranslateStatus(false);
    setTranslateReplyStatus("translateReply");
    setTranslationText("");
    replyInputRef.current?.focus?.();
  };
  const handleChangeTranslation = (event) => {
    setTranslationText(event.target.value);
  };
  const handleFocus = (event) => {
    setTextareaState(event.target.id);
  };
  const translateReplyHandler = () => {
    setTranslationTextLoading(true);
    const queryData = {
      text,
      target_language: isTranslationSourceAutoDetect
        ? translationSourceText
        : translationSource,
      source_language: translationTarget,
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.detectTranslate(queryData).then((res) => {
      if (!res?.errorMsg) {
        setTranslationText(res?.data?.translation);
        setTranslateReplyStatus("replyTranslated");
      }
      setTranslationTextLoading(false);
      replyInputRef?.current?.focus?.();
    });
  };
  const getSourceLanguage = (target, source) => {
    setTranslationSourceTextLoading(true);
    const clientName = selectedCard?.client_name;
    let lastMsg = "";
    if (selectedCard?.isDm) {
      lastMsg =
        [...chatData]
          ?.reverse()
          ?.find((item) => item?.name === clientName && item?.content?.text)
          ?.content?.text || "";
    } else {
      lastMsg = selectedCard?.text || "";
    }
    if (lastMsg) {
      const sourceValue = translationSource || source;
      const queryData = {
        text: lastMsg,
        target_language: translationTarget || target,
        source_language:
          sourceValue !== "auto_detect" ? translationSource : null,
        product_id: window.localStorage.getItem("engagementsProductId"),
      };
      EngagementsController.detectTranslate(queryData).then((res) => {
        if (!res?.errorMsg) {
          setTranslationSourceText(res?.data?.source_language);
        } else {
          setTranslationSourceText("en");
        }
      });
    } else {
      setTranslationSourceText("en");
    }
    setTranslationSourceTextLoading(false);
  };

  const replyTranslationDisabledButton = text?.trim()?.length === 0;
  const replyTranslationDisabledText =
    translateReplyStatus === "translateReply";
  const replyTranslationTextClass =
    translationText.length !== 0 || translateReplyStatus !== "translateReply";

  const handleAutoAssign = () => {
    const queryData = {
      id:
        selectedCard?.ai_agent?.ai_agent_id ||
        selectedCard?.user_email?.split("_")?.[2],
      conversation_id: selectedCard?.conversation_id,
      tracker_id: selectedCard?.tracker_id,
      data_source:
        selectedCard?.data_source?.toLowerCase() +
        "_" +
        selectedCard?.channel_type?.toLowerCase(),
    };
    setAutoReplyMessage(null);
    autoAssignAiAgent(queryData, {
      onSuccess: () => {
        setRefresh();
        setOpenSnackBarAiAgent(true);
        setSnackBarDataAiAgent({
          severity: "success",
          message: "engagement_assigned_succussfully",
        });
      },
      onError: (error) => {
        if (error?.response?.data?.error === "Can't assign to team") {
          setOpenPopupTeamUnavailable(true);
          setAutoReplyMessage(error?.response?.data?.auto_reply_message || "");
        } else {
          setOpenSnackBarAiAgent(true);
          setSnackBarDataAiAgent({
            severity: "error",
            title: "failed_error_message",
            message: "try_again_error_message",
          });
        }
      },
    });
  };

  // below useEffect is used for setting the reply to users for public twitter engagement
  useEffect(() => {
    if (!selectedCard?.isDm && selectedCard?.data_source === "TWITTER") {
      // here we have two sources for the user mentions, entities and mention_info
      // if mention_info is not available, we will parse the entities to get the user mentions

      // orignal poster may not be returned in mention_info if the tweet does't have any mentions
      const originalPoster = {
        username: selectedCard?.screen_name,
        id: selectedCard?.screen_name,
        profile_image_url: selectedCard?.profile_image_url,
        name: selectedCard?.name,
        postOwner: true,
      };

      const parseUserMentions = (entities) => {
        try {
          const parsedEntities = JSON.parse(entities);
          return (
            parsedEntities?.user_mentions?.map((user) => ({
              ...user,
              id: user?.id_str, // we need to use id_str as id will be string
              username: user?.screen_name,
              postOwner: false,
            })) ?? []
          );
        } catch {
          return [];
        }
      };

      const parseMentionInfo = (mentionInfo) =>
        mentionInfo?.map((user) => ({
          ...user,
          id: user?.id, // id will be string
          postOwner: user?.username === selectedCard?.screen_name,
        })) ?? [];

      const userMentions = _.uniqBy(
        [
          originalPoster,
          ...(selectedCard?.mention_info?.length
            ? parseMentionInfo(selectedCard?.mention_info)
            : parseUserMentions(selectedCard?.entities)),
        ],
        // remove duplicates by username
        "username",
      );
      setReplyToUsers(userMentions);
      setReplyToSelectedUsers(userMentions);
    }
  }, [selectedCard]);

  useEffect(() => {
    selectedValueRef.current = selectedValue;
  }, [selectedValue]);
  return (
    <Box
      className={classNames(
        "main-posts-footer",
        isTextInputFocused ? "bordered-reply" : "",
      )}
    >
      <Box className="engagements-reply">
        {!showSuccessModal &&
        !showErrorModal &&
        (replyData?.system_replies?.is_replied === undefined ||
          replyData?.system_replies?.is_replied === 0) ? (
          <>
            {aiAgentId && delayed ? (
              <AIAgentResponseTime aiAgentDetails={aiAgentDetails} />
            ) : aiAgentId ? (
              <Typography className="ai-agent-reply-container">
                <FontAwesomeIcon icon={faLock} />
                {CheckValueLocale("handled_by_ai_agent", "", {}, intl)}
              </Typography>
            ) : (
              <div className="reply-container">
                {assignedUser && authorizedToReply ? (
                  <>
                    {replyToUsers?.length &&
                    selectedCard?.data_source === "TWITTER" &&
                    !selectedCard?.isDm ? (
                      <TwitterReplyTo
                        users={replyToUsers}
                        selectedUsers={replyToSelectedUsers}
                        setSelectedUsers={setReplyToSelectedUsers}
                        handleTextInputFocus={handleTextInputFocus}
                      />
                    ) : null}
                    <TextField
                      value={text}
                      inputRef={replyInputRef}
                      onChange={handleChangeText}
                      id="original-text"
                      onFocus={(event) => {
                        handleFocus(event);
                        setIsTextInputFocused(true);
                      }}
                      onBlur={() => {
                        setIsTextInputFocused(false);
                      }}
                      multiline
                      maxRows={7}
                      variant="outlined"
                      placeholder={CheckValueLocale(
                        "enter_reply",
                        "",
                        {},
                        intl,
                      )}
                      className="reply-textarea"
                      error={textLength > maxChar}
                      disabled={btnLoading}
                    />
                  </>
                ) : (
                  <LockedEngagement
                    reduxSlaKey={reduxSlaKeys}
                    userSignedIn={userSignedIn}
                    selectedCard={itemSelected}
                    setEngamentAssignedSnackBar={setEngamentAssignedSnackBar}
                    trackerId={selectedCard?.tracker_id}
                    updateReduxAfterAssign={updateReduxAfterAssign}
                  />
                )}
                {translateStatus ? (
                  <div className="reply-translation-container">
                    {!translationSourceTextLoading ? (
                      <>
                        <div className="reply-translation-heading">
                          {translateReplyStatus === "replyTranslated" ? (
                            <p className="reply-translated-hint">
                              <FontAwesomeIcon icon={faCheckCircleRegular} />
                              {CheckValueLocale(
                                "reply_translated",
                                "",
                                {},
                                intl,
                              )}
                            </p>
                          ) : (
                            <Button
                              variant="outlined"
                              className="reply-translation-button"
                              onClick={translateReplyHandler}
                              disabled={replyTranslationDisabledButton}
                            >
                              {translationTextLoading ? (
                                <CircularProgress className="circular-progress-translation" />
                              ) : (
                                <>
                                  {translateReplyStatus === "translateReply" ? (
                                    <>
                                      <img src={translateButton} />
                                      {CheckValueLocale(
                                        "translate_reply",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <img src={update} />
                                      {CheckValueLocale(
                                        "update_translation",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    </>
                                  )}{" "}
                                  {CheckValueLocale("from_lang", "", {}, intl)}{" "}
                                  ({translationTarget}){" "}
                                  {CheckValueLocale("to_lang", "", {}, intl)} (
                                  {isTranslationSourceAutoDetect
                                    ? translationSourceText
                                    : translationSource}
                                  )
                                </>
                              )}
                            </Button>
                          )}
                          <Box
                            className={`reply-translation-close ${
                              btnLoading ? "reply-footer-icon-disabled" : ""
                            }`}
                            onClick={!btnLoading ? closeTranslationBox : null}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Box>
                        </div>
                        <TextField
                          value={translationText}
                          onChange={handleChangeTranslation}
                          onFocus={handleFocus}
                          id="translation-text"
                          multiline
                          maxRows={7}
                          variant="outlined"
                          placeholder={CheckValueLocale(
                            "translate_reply_placeholder",
                            "",
                            {},
                            intl,
                          )}
                          className={`reply-translation-textarea ${
                            replyTranslationTextClass
                              ? "reply-translated-textarea"
                              : ""
                          }`}
                          disabled={replyTranslationDisabledText}
                        />
                      </>
                    ) : (
                      <div className="translation-text-loading">
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>
                ) : null}
                {base64Code?.length !== 0 && (
                  <div className="reply-attachments-container">
                    {base64Code?.map((base64, index) => {
                      return (
                        <div className="reply-attachment">
                          {selectedFile[index]?.type.includes("image") ? (
                            <div className="attachments-img-box">
                              <img
                                src={base64}
                                alt="Uploaded"
                                className="attachments-img"
                              />
                              {selectedFile[index]?.type === "image/gif" ? (
                                <div className="attachments-icon-box">
                                  <img src={gifSolid} />
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <div className="attachments-video-box">
                              <VideoFrame
                                videoUrl={base64}
                                thumbnailHandler={(thumbnail) => (
                                  <img
                                    src={thumbnail}
                                    alt="Video Thumbnail"
                                    style={{ maxWidth: "100%" }}
                                  />
                                )}
                                width={33}
                                height={33}
                              />
                              <div className="attachments-icon-box">
                                <FontAwesomeIcon icon={faPlay} />
                              </div>
                            </div>
                          )}
                          <div
                            className="remove-attachments-reply"
                            onClick={() => handleRemoveAttachments(index)}
                          >
                            <span className="trash-wrap">
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {mediaErr ? (
              <span className="media-err">
                {CheckValueLocale(mediaErr, "", {}, intl)}
              </span>
            ) : null}
            <Box className="reply-footer">
              <div className="reply-footer-box">
                {loading ? (
                  <Box className="account-reply-loading">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={34}
                      height={34}
                    />
                    <Skeleton animation="pulse" height={11} width="100px" />
                  </Box>
                ) : (
                  <Box className="reply-footer-dropdown">
                    {selectedValue?.displayName && authorizedToReply ? (
                      iconToDisplay !== null ||
                      selectedCard?.data_source === "WHATSAPP" ? (
                        <Avatar
                          src={selectedValue?.image_url}
                          variant="rounded"
                          className="account-reply-avater"
                        />
                      ) : (
                        <div className="account-box-avater account-default-reply">
                          <DefaultAccountImg
                            selectedValue={
                              selectedCard?.data_source === "WHATSAPP"
                                ? selectedValue?.displayName?.replace?.("+", "")
                                : selectedValue?.displayName
                            }
                          />
                        </div>
                      )
                    ) : (
                      <div className="account-box-avater account-reply-empty"></div>
                    )}
                    <Select
                      labelId="engagements-reply-select-label"
                      id="engagements-reply-select"
                      className={`engagements-reply-select ${!authorizedToReply ? "disable-eng-reply-select-hover" : ""}`}
                      value={selectedValue}
                      onChange={handleChangeAccount}
                      displayEmpty
                      IconComponent={ArrowDropDownRoundedIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return CheckValueLocale("reply_by", "", {}, intl);
                        }
                        const displayName =
                          selectedCard?.data_source === "WHATSAPP" &&
                          selectedValue?.displayName
                            ? getResetPhoneNumber(selectedValue?.displayName)
                            : selectedValue?.displayName;
                        return (
                          <Tooltip title={displayName} placement="top" arrow>
                            <span>{getNumberOfChar(displayName, intl)}</span>
                          </Tooltip>
                        );
                      }}
                      disabled={aiAgentId || !authorizedToReply}
                    >
                      <MenuItem
                        value=""
                        disabled={true}
                        className="reply-option"
                      >
                        <Box
                          className="no-accounts-to-reply-sla"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              CheckValueLocale(
                                !accountsList?.length
                                  ? "no_permission"
                                  : !haveAccounts || !haveActiveAcc
                                    ? "no_accounts"
                                    : "reply_by",
                                "",
                                {},
                                intl,
                              ),
                            ),
                          }}
                        />
                      </MenuItem>
                      {accountsList?.map((account, i) => {
                        let option = getNumberOfChar(
                          account?.displayName,
                          intl,
                        );

                        return (replyType === "dm" &&
                          ((selectedCard?.data_source === "TWITTER" &&
                            "@" + selectedCard?.agent_username ===
                              account?.displayName) ||
                            checkIdAccountDm(
                              accountsList,
                              selectedCard?.data_source,
                              account?.displayName,
                            ))) ||
                          replyType !== "dm" ? (
                          <MenuItem
                            value={account}
                            disabled={account?.status !== "active"}
                          >
                            <Box className="account-dropdown-list">
                              <div className="list-data-img">
                                {account?.image_url !== null ? (
                                  <Avatar
                                    src={account?.image_url}
                                    variant="rounded"
                                    className="account-reply-avater"
                                  />
                                ) : (
                                  <div className="account-box-avater account-default-reply">
                                    <DefaultAccountImg selectedValue={option} />
                                  </div>
                                )}
                                <span
                                  className={`list-data-icon ${selectedCard?.data_source?.toLowerCase()}-icon`}
                                >
                                  {selectedCard?.data_source === "TWITTER" ? (
                                    <img
                                      src={xPlatform}
                                      alt="x-platform-logo"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={getDatasourceIconName(
                                        selectedCard?.data_source,
                                      )}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="list-data-text">
                                <span className="list-data-option">
                                  {selectedCard?.data_source === "WHATSAPP"
                                    ? getResetPhoneNumber(option)
                                    : option}
                                </span>
                                <span className="list-data-source">
                                  {CheckValueLocale(
                                    selectedCard?.data_source,
                                    "",
                                    {},
                                    intl,
                                  )?.toLowerCase()}
                                </span>
                              </div>
                            </Box>
                          </MenuItem>
                        ) : null;
                      })}
                    </Select>
                  </Box>
                )}
                <Divider
                  orientation="vertical"
                  className="engagements-reply-divider"
                />
                <Tooltip
                  title={
                    !aiAgentId
                      ? CheckValueLocale("saved_replies", "", {}, intl)
                      : null
                  }
                  placement="top"
                  arrow
                  disableHoverListener={!authorizedToReply}
                >
                  <Box
                    className={`reply-footer-icon ${
                      aiAgentId || !authorizedToReply
                        ? "reply-footer-icon-disabled"
                        : ""
                    }`}
                    onClick={
                      !aiAgentId && authorizedToReply ? handleClick : null
                    }
                    id="saved-replies-btn"
                  >
                    <FontAwesomeIcon
                      icon={
                        openDetailsSideBarReplay
                          ? faCommentAltLinesSolid
                          : faCommentAltLines
                      }
                    />
                  </Box>
                </Tooltip>
                <Tooltip
                  title={
                    !aiAgentId
                      ? CheckValueLocale(
                          translateStatus
                            ? "turn_off_translation"
                            : "turn_on_translation",
                          "",
                          {},
                          intl,
                        )
                      : null
                  }
                  placement="top"
                  arrow
                  disableHoverListener={!authorizedToReply}
                >
                  <Box
                    id="engagemnt-footer-translation-btn"
                    className={`reply-footer-icon ${
                      btnLoading || aiAgentId || !authorizedToReply
                        ? "reply-footer-icon-disabled"
                        : ""
                    }`}
                    onClick={
                      !btnLoading && !aiAgentId && authorizedToReply
                        ? translateHandler
                        : null
                    }
                  >
                    <img
                      src={
                        translationActive && translateStatus
                          ? activeTranselation
                          : translate
                      }
                      className={`${
                        translationActive &&
                        translateStatus &&
                        "active-transelation-reply-icon"
                      }`}
                    />
                  </Box>
                </Tooltip>
                <Popover
                  anchorOrigin={anchorPositionTranslation}
                  transformOrigin={transformPositionTranslation}
                  onClose={handleCloseTranslation}
                  open={openTranslation}
                  anchorEl={anchorElTranslation}
                >
                  <EngagementTranslation
                    translationType="reply"
                    setTranslationActive={setTranslationActive}
                    translationSource={translationSource}
                    setTranslationSource={setTranslationSource}
                    translationTarget={translationTarget}
                    setTranslationTarget={setTranslationTarget}
                    handleCloseTranslation={handleCloseTranslation}
                    stats={stats}
                    setStats={setStats}
                    languagesArraySource={languagesArraySource}
                    languagesArrayTarget={languagesArrayTarget}
                    setTranslateStatus={setTranslateStatus}
                    getSourceLanguage={getSourceLanguage}
                  />
                </Popover>
                <Tooltip
                  title={
                    !aiAgentId
                      ? CheckValueLocale("emoji_tooltip", "", {}, intl)
                      : null
                  }
                  placement="top"
                  arrow
                  disableHoverListener={!authorizedToReply}
                >
                  <Box
                    className={`reply-footer-icon emoji-icon ${
                      aiAgentId || !authorizedToReply
                        ? "reply-footer-icon-disabled"
                        : ""
                    }`}
                    onClick={
                      !aiAgentId && authorizedToReply ? handleClickEmoji : null
                    }
                  >
                    <FontAwesomeIcon icon={faGrinAlt} />
                  </Box>
                </Tooltip>
                {!showAllIcon ? (
                  <>
                    <Tooltip
                      title={CheckValueLocale("more", "", {}, intl)}
                      placement="top"
                      arrow
                    >
                      <Box
                        className="reply-footer-icon"
                        onClick={handleViewMoreIconsClick}
                      >
                        <FontAwesomeIcon icon={faEllipsis} />
                      </Box>
                    </Tooltip>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={viewMoreIconsEl}
                      open={isViewMoreIconsOpen}
                      onClose={handleViewMoreIconsClose}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      PaperProps={{
                        style: {
                          maxHeight: 53,
                        },
                        classes: {
                          root: "replay-footer-veiw-more-icon-root",
                        },
                      }}
                    >
                      <IconReplyEllipsis
                        attachments={attachments}
                        handleAttachmentsUpload={handleAttachmentsUpload}
                        disabledStyleFirst={disabledStyleFirst}
                        disabledCondition={disabledCondition}
                        mediaLength={mediaLength}
                        inputRefGif={inputRefGif}
                        inputRefVideo={inputRefVideo}
                        inputRefImg={inputRefImg}
                        handleClickEmoji={handleClickEmoji}
                        aiAgentId={aiAgentId}
                        fromDropdown={true}
                        assignee={reduxSlaData[reduxSlaKeys]}
                        userSignedInInteract={userSignedIn}
                        dataSource={selectedCard?.data_source}
                        replyType={replyType}
                      />
                    </Menu>
                  </>
                ) : (
                  <>
                    <Divider
                      orientation="vertical"
                      className="engagements-reply-divider"
                    />
                    <IconReplyEllipsis
                      attachments={attachments}
                      handleAttachmentsUpload={handleAttachmentsUpload}
                      disabledStyleFirst={disabledStyleFirst}
                      disabledCondition={disabledCondition}
                      mediaLength={mediaLength}
                      inputRefGif={inputRefGif}
                      inputRefVideo={inputRefVideo}
                      inputRefImg={inputRefImg}
                      handleClickEmoji={handleClickEmoji}
                      aiAgentId={aiAgentId}
                      assignee={reduxSlaData?.[reduxSlaKeys]}
                      userSignedInInteract={userSignedIn}
                      dataSource={selectedCard?.data_source}
                      replyType={replyType}
                    />
                  </>
                )}
                <Popover
                  anchorOrigin={emojyAnchorOrigin}
                  transformOrigin={emojyTransformOrigin}
                  onClose={handleCloseEmoji}
                  open={openEmoji}
                  anchorEl={anchorElEmoji}
                  classes={{
                    paper: "reply-box-emoji-popover",
                    root: "reply-box-emoji-popover-root",
                  }}
                >
                  <Picker
                    data={data}
                    onEmojiSelect={(e) => emojiSelect(e?.native)}
                  />
                </Popover>
              </div>
              {!aiAgentId && authorizedToReply ? (
                <div className="reply-footer-box">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    className={`reply-word-breadcrumbs ${breadCrumbsStyle}`}
                  >
                    <Link color="inherit">{textLength}</Link>
                    <Link color="inherit">{maxChar}</Link>
                  </Breadcrumbs>
                  <LucButton
                    disabled={replyBtnStyle}
                    loading={btnLoading}
                    onClick={handleReply}
                    size="small"
                    id="engagements-send-reply"
                    className="engagements-send-reply"
                    endIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
                  >
                    {CheckValueLocale(
                      translateStatus ? "send_translation" : "send",
                      "",
                      {},
                      intl,
                    )}
                  </LucButton>
                </div>
              ) : aiAgentId ? (
                <div className="reply-footer-box-ai-agent">
                  <LucButton
                    size="small"
                    variant="outline"
                    id="engagements-ai-agent-auto-assign"
                    onClick={handleAutoAssign}
                    loading={autoAssignAiAgentLoading}
                    disabled={autoAssignAiAgentLoading}
                  >
                    {CheckValueLocale("auto_assign", "", {}, intl)}
                  </LucButton>
                  <LucButton
                    size="small"
                    id="engagements-ai-agent-assign-human"
                    onClick={() => setOpenPopupAssignHuman(true)}
                  >
                    {CheckValueLocale("assign_to_human", "", {}, intl)}
                  </LucButton>
                </div>
              ) : null}
            </Box>
          </>
        ) : showSuccessCondition ? (
          successModal()
        ) : (
          errorModal()
        )}

        {openPopupAssignHuman ? (
          <AssignHumanAiAgentModal
            selectedCard={selectedCard}
            openPopupAssignHuman={openPopupAssignHuman}
            setOpenPopupAssignHuman={setOpenPopupAssignHuman}
            setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
            setSnackBarDataAiAgent={setSnackBarDataAiAgent}
            setInjectedMessage={setInjectedMessage}
            aiAgentDetails={aiAgentDetails}
            setRefresh={setRefresh}
            chatData={chatData}
          />
        ) : null}
        {openPopupTeamUnavailable && autoReplyMessage !== null ? (
          <TeamUnavailableAiAgentModal
            selectedCard={selectedCard}
            openPopupTeamUnavailable={openPopupTeamUnavailable}
            setOpenPopupTeamUnavailable={setOpenPopupTeamUnavailable}
            setRefresh={setRefresh}
            setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
            setSnackBarDataAiAgent={setSnackBarDataAiAgent}
            chatData={chatData}
            autoReplyMessage={autoReplyMessage}
          />
        ) : null}
        {showSnackBar && (
          <SnackBar
            open={showSnackBar}
            autoHideDuration={5000}
            severity={snackBarSeverity}
            message={CheckValueLocale(
              snackBarMessage,
              "",
              { time: limitRateDate },
              intl,
            )}
            title={CheckValueLocale(snackBarTitle, "", {}, intl)}
            handleClose={handleCloseSnack}
          />
        )}
        <SnackBar
          snackBarId="locked-engagement-assign-submit-snackbar"
          open={engamentAssignedSnackBar?.openSnackBar}
          handleClose={() => setEngamentAssignedSnackBar({})}
          severity={engamentAssignedSnackBar?.severity}
          title={CheckValueLocale(
            engamentAssignedSnackBar?.title,
            "",
            {},
            intl,
          )}
          message={CheckValueLocale(
            engamentAssignedSnackBar?.message,
            "",
            {},
            intl,
          )}
        />
        {/* {isOnline && connectionSnack ? (
          <Box id="main-network-faild">
            <SnackBar
              open={connectionSnack}
              autoHideDuration={5000}
              severity={"error"}
              message={
                <Box id="network-faild-snack">
                  <Box>{CheckValueLocale("network_reload", "", {}, intl)}</Box>
                  <Button onClick={() => window.location.reload()}>
                    {CheckValueLocale("network_try_again", "", {}, intl)}
                  </Button>
                </Box>
              }
            />
          </Box>
        ) : null} */}
      </Box>
    </Box>
  );
};

export default Reply;
