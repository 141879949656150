import { useIntl } from "react-intl";
import { Box, Button, Tooltip, Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale, classNames } from "utils/helpers";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import "./editAndDeleteButtons.scss";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses?.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses?.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "10px 8px",
    fontWeight: "500",
  },
}));

const EditAndDeleteButtons = (props) => {
  const intl = useIntl();
  const {
    handleOpenDeleteModal,
    deleteTooltip,
    editTooltip,
    setActiveReplyEdit,
    activeReplyEdit,
    index,
    setActiveReplyEditEngagement,
    isDeletedResponse,
    isTweetLink,
    hideEditIcon,
    hideDeleteIcon,
    disabled,
    isStatusComplete,
  } = props;
  const isDeleteEditBtn = isDeletedResponse;

  const activeEditMode = () => {
    if (activeReplyEdit) {
      activeReplyEdit[index] = true;
      setActiveReplyEdit([...activeReplyEdit]);
    } else {
      setActiveReplyEditEngagement(true);
    }
  };

  return (
    <Box
      className={`${
        isTweetLink ? "edit-delete-buttons-link" : "edit-delete-buttons"
      }`}
    >
      {!hideEditIcon ? (
        <BootstrapTooltip
          title={
            isStatusComplete
              ? CheckValueLocale(
                  "status_complete_disabled_tooltip",
                  "",
                  {},
                  intl,
                )
              : CheckValueLocale(editTooltip, "", {}, intl)
          }
          arrow
          placement="top"
          TransitionComponent={Zoom}
        >
          <Box
            onClick={disabled ? null : activeEditMode}
            className={classNames(
              "edit-icon-wrapper",
              isDeleteEditBtn && "disable-del-edit",
              disabled && "disable-not-auth-edit-delete",
              disabled &&
                !isStatusComplete &&
                "disable-not-auth-edit-delete-not-complete-case",
            )}
          >
            <FontAwesomeIcon
              className={`${disabled && "disable-not-auth-edit-delete-icons"}`}
              icon={faPen}
            />
          </Box>
        </BootstrapTooltip>
      ) : null}
      {!hideDeleteIcon ? (
        <BootstrapTooltip
          title={
            isStatusComplete
              ? CheckValueLocale(
                  "status_complete_disabled_tooltip",
                  "",
                  {},
                  intl,
                )
              : CheckValueLocale(deleteTooltip, "", {}, intl)
          }
          arrow
          placement="top"
          TransitionComponent={Zoom}
        >
          <Box
            onClick={(event) => {
              disabled ? null : handleOpenDeleteModal(event);
            }}
            className={classNames(
              "delete-icon-wrapper",
              disabled && "disable-not-auth-edit-delete",
              disabled &&
                !isStatusComplete &&
                "disable-not-auth-edit-delete-not-complete-case",
            )}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={`saved-result-delete-icon ${disabled && "disable-not-auth-edit-delete-icons"}`}
            />
          </Box>
        </BootstrapTooltip>
      ) : null}
    </Box>
  );
};

export default EditAndDeleteButtons;
